import { yupResolver } from "@hookform/resolvers/yup";
import {
  Avatar,
  Button,
  Divider,
  Group,
  LoadingOverlay,
  Rating,
  Select,
  Skeleton,
  Stack,
  Text,
  Textarea,
  Title,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { IconArrowLeft, IconDeviceFloppy } from "@tabler/icons";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { debounce } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Category } from "../../../models/category.model";
import { CollectionQuery } from "../../../models/collection.model";
import { DriverFor, Provider } from "../../../models/provider.model";
import { RouteAssignment } from "../../../models/route-assignment.model";
import { Route } from "../../../models/route.model";
import Card from "../../../shared/component/Card/card-component";
import { pickupTimeType } from "../../../shared/utility/enum/pickupTypeTimes.enum";
import { downloadUrlParser } from "../../../shared/utility/Tools/tools";
import { useLazyGetCategoriesQuery } from "../../category/store/category.query";
import { useLazyGetProvidersQuery } from "../../provider/store/provider.query";
import { useLazyGetRoutesQuery } from "../../route/store/route.query";
import {
  useAddRouteAssignmentMutation,
  useLazyGetRouteAssignmentQuery,
  useUpdateRouteAssignmentMutation,
} from "../store/route-assignment.query";

type RouteAssignmentFormProps = {
  editMode: "new" | "detail";
};

dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);
let schema = Yup.object().shape({
  routeId: Yup.string().required("Route is required"),
  driverId: Yup.string().required("Driver is required"),
  shift: Yup.string().required("Shift is required"),
  driverStartDate: Yup.date().required("Driver's start date  is required"),
  assignmentStartDate: Yup.date().required("Trip start date  is required"),
  remark: Yup.string().nullable(),
  // assignmentWeekDays: Yup.array()
  //   .of(Yup.string())
  //   .required("Please select at least one day"),
  morningPickupTime: Yup.string()
    .nullable()
    .when("shift", {
      is: (value: string) =>
        value === pickupTimeType.Both || value === pickupTimeType.Morning,
      then: Yup.string().required("Morning pickup time is required"),
    }),
  afternoonPickupTime: Yup.string()
    .nullable()
    .when("shift", {
      is: (value: string) =>
        value === pickupTimeType.Both || value === pickupTimeType.Afternoon,
      then: Yup.string().required("Afternoon pickup time is required"),
    }),
});
function RouteAssignmentFormComponent(props: RouteAssignmentFormProps) {
  const { editMode } = props;
  const params = useParams();
  const navigate = useNavigate();
  const defaultValue: RouteAssignment = {
    id: "",
    morningPickupTime: "",
    afternoonPickupTime: "",
    routeId: "",
    driverId: "",
    shift: "",
    assignmentWeekDays: [],
    remark: "",
    driverStartDate: undefined,
    driverEndDate: undefined,
    assignmentStartDate: undefined,
  };

  const [selectedDriver, setSelectedDriver] = useState<Provider>();
  const [selectedRoute, setSelectedRoute] = useState<Route>();
  const [selectedRouteTimeTypes, setSelectedRouteTimeTypes] = useState([
    { label: "Both", value: pickupTimeType.Both },
    { label: "Morning", value: pickupTimeType.Morning },
    { label: "Afternoon", value: pickupTimeType.Afternoon },
  ]);
  const [preferredRoute, setPreferredRoute] = useState<string | null>(null);
  const [preferredDate, setPreferredDate] = useState<string | null>(null);
  const [preferredCategory, setPreferredCategory] = useState<string | null>(
    null
  );
  const [getRouteAssignment, routeAssignment] =
    useLazyGetRouteAssignmentQuery();
  const [getRoutes, routes] = useLazyGetRoutesQuery();
  const [getDrivers, drivers] = useLazyGetProvidersQuery();
  const [getCategories, categories] = useLazyGetCategoriesQuery();
  const [addRouteAssignment, newRouteAssignment] =
    useAddRouteAssignmentMutation();
  const [updateRouteAssignment, updatedRouteAssignment] =
    useUpdateRouteAssignmentMutation();
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<RouteAssignment>({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const selectedTimeType = watch("shift");
  const selectedRouteId = watch("routeId");
  const selectedDriverId = watch("driverId");
  const [routeCollection, setRouteCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });
  const [driverCollection, setDriverCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });

  function parseDrivers() {
    const data: { value: string; label: string }[] = [];
    if (drivers.data) {
      drivers?.data?.data.map((driver: Provider) => {
        let label = driver.name;
        if (driver.vehicle) {
          label =
            label +
            " (" +
            driver?.vehicle?.model +
            ", " +
            driver?.vehicle?.plateNumber +
            ")";
          data.push({
            value: driver.id ?? "",
            label: label,
          });
        }
      });
    }
    if (routeAssignment?.data?.driver) {
      data.push({
        value: routeAssignment?.data?.driver?.id ?? "",
        label: `${routeAssignment?.data?.driver?.name} (${routeAssignment?.data?.driver?.vehicle?.model}, ${routeAssignment?.data?.driver?.vehicle?.plateNumber})`,
      });
    }
    if (data.length === 0) {
      return [{ value: "empty", label: "List empty", disabled: true }];
    }
    return data;
  }

  function parseRoutes() {
    const data: { value: string; label: string }[] = [];
    if (routes.data) {
      const withStationsRoutes = routes?.data?.data?.filter((eachRoute) => {
        return eachRoute.stations?.length
          ? eachRoute.stations.length > 0
          : false;
      });

      withStationsRoutes?.map((route: Route) => {
        let label = route.name;
        if (route?.city) {
          label += " (" + route.city.name + ")";
        }
        data.push({
          value: route.id ?? "",
          label: label,
        });
      });
      const routeToAdd = {
        value: routeAssignment?.data?.route?.id || "",
        label: `${routeAssignment?.data?.route?.name}`,
      };
      if (
        routeAssignment?.data?.route &&
        !data?.some((route) => route.value === routeToAdd.value)
      ) {
        data?.unshift(routeToAdd);
      }
    }
    if (data.length === 0) {
      return [{ value: "empty", label: "List empty", disabled: true }];
    }
    return data;
  }
  function parseCategories() {
    const data: { value: string; label: string }[] = [];
    if (categories.data) {
      categories?.data?.data.map((category: Category) => {
        data.push({
          value: category.id ?? "",
          label: category.name,
        });
      });
    }

    return data;
  }
  useEffect(() => {
    if (selectedRoute?.shift == pickupTimeType.Morning) {
      setSelectedRouteTimeTypes([
        { label: "Morning", value: pickupTimeType.Morning },
      ]);
    } else if (selectedRoute?.shift == pickupTimeType.Afternoon) {
      setSelectedRouteTimeTypes([
        { label: "Afternoon", value: pickupTimeType.Afternoon },
      ]);
    } else {
      setSelectedRouteTimeTypes([
        { label: "Both", value: pickupTimeType.Both },
        { label: "Morning", value: pickupTimeType.Morning },
        { label: "Afternoon", value: pickupTimeType.Afternoon },
      ]);
    }
  }, [selectedRoute]);
  useEffect(() => {
    getCategories({ skip: 0, top: 100 });
  }, [getCategories]);
  useEffect(() => {
    drivers?.data?.data.map((d) => {
      if (d.id === selectedDriverId) {
        setSelectedDriver(d);
      }
    });
  }, [drivers, selectedDriverId]);

  useEffect(() => {
    const data = routes?.data?.data;
    let newDataa: any[] = []; // Initialize with an empty array
    const routeToAdd = routeAssignment?.data?.route;

    if (
      data &&
      routeToAdd &&
      !data.some((route) => route?.id === routeToAdd.id)
    ) {
      newDataa = [routeToAdd, ...data]; // Create a new array with the new route
    } else {
      newDataa = data || []; // Use the existing data or fallback to an empty array
    }

    newDataa.forEach((r) => {
      if (r.id === selectedRouteId) {
        setSelectedRoute(r);
      }
    });
  }, [routes, routeAssignment, selectedRouteId]);

  useEffect(() => {
    getRoutes({
      ...routeCollection,
      filter: [[{ field: "isActive", value: true, operator: "=" }]],
      includes: ["stations", "city"],
    });
  }, [routeCollection]);

  useEffect(() => {
    getDrivers({
      ...driverCollection,
      includes: ["preferences"],
      filter: [
        [{ field: "vehicleId", value: "", operator: "notNull" }],
        [{ field: "enabled", value: true, operator: "=" }],
        [{ field: "driverFor", value: DriverFor.KabbaKids, operator: "!=" }],
      ],
    });
  }, [driverCollection]);

  useEffect(() => {
    if (editMode === "detail") {
      getRouteAssignment(`${params?.id}`).then((response: any) => {
        if (response?.data) {
          const driverStartDate = response?.data?.driverStartDate
            ? new Date(response?.data?.driverStartDate)
            : null;
          const driverEndDate = response?.data?.driverEndDate
            ? new Date(response?.data?.driverEndDate)
            : null;
          const assignmentStartDate = response?.data?.assignmentStartDate
            ? new Date(response?.data?.assignmentStartDate)
            : null;
          reset({
            ...response?.data,
            driverStartDate: driverStartDate,
            driverEndDate: driverEndDate,
            assignmentStartDate: assignmentStartDate,
          });
          // reset(response?.data);
        }
      });
    }
  }, []);

  function onSubmit(data: RouteAssignment) {
    if (editMode === "new") {
      //@ts-ignore
      const driverStartDate = new Date(data.assignmentStartDate);
      driverStartDate.setMinutes(
        driverStartDate.getMinutes() - driverStartDate.getTimezoneOffset()
      );
      //@ts-ignore
      const driverEndDate = new Date(data.driverEndDate);
      driverEndDate.setMinutes(
        driverEndDate.getMinutes() - driverEndDate.getTimezoneOffset()
      );
      //@ts-ignore
      const assignmentStartDate = new Date(data.assignmentStartDate);
      assignmentStartDate.setMinutes(
        assignmentStartDate.getMinutes() -
          assignmentStartDate.getTimezoneOffset()
      );

      addRouteAssignment({
        ...data,
        driverStartDate: driverStartDate,
        driverEndDate: driverEndDate,
        assignmentStartDate: assignmentStartDate,
      }).then((response: any) => {
        if (response?.data) {
          reset(defaultValue);
        }
      });
    } else {
      updateRouteAssignment({ ...data, id: `${params.id}` }).then(
        (response: any) => {
          if (response?.data) {
            const driverStartDate = new Date(response?.data?.driverStartDate);
            const driverEndDate = new Date(response?.data?.driverEndDate);
            const assignmentStartDate = new Date(
              response?.data?.assignmentStartDate
            );
            reset({
              ...response?.data,
              driverStartDate: driverStartDate,
              driverEndDate: driverEndDate,
              assignmentStartDate: assignmentStartDate,
            });
          }
        }
      );
    }
  }
  const onError = (errors: any, e: any) => {
    console.log("errors", errors);
  };
  function Levels(level: string) {
    switch (level) {
      case "easy": {
        return (
          <Text c={"teal"} fw={500}>
            Easy
          </Text>
        );
      }
      case "medium": {
        return (
          <Text c={"blue"} fw={500}>
            Medium
          </Text>
        );
      }
      case "hard": {
        return (
          <Text c={"grape"} fw={500}>
            Hard
          </Text>
        );
      }
      case "moderate": {
        return (
          <Text c={"blue"} fw={500}>
            Moderate
          </Text>
        );
      }
      case "extreme": {
        return (
          <Text c={"red"} fw={500}>
            Extreme
          </Text>
        );
      }
    }
  }
  console.log("city", selectedRoute);

  return (
    <div className="h-full p-4">
      <Card
        titleParentClassNames={"w-full"}
        title={
          <Group position={"apart"} className={"w-full"}>
            <Text>
              {editMode === "new" ? "New" : "Update"} Route Assignment
            </Text>
            <Link to={"/assignment"}>
              <Button
                className="text-white bg-primary"
                leftIcon={<IconArrowLeft />}
              >
                Back
              </Button>
            </Link>
          </Group>
        }
        className={"p-2"}
      >
        <Group position={"apart"} grow className={"items-start mt-5"}>
          <div className="w-full h-full">
            <form
              onSubmit={handleSubmit(onSubmit, onError)}
              className="w-full gap-y-4 dark:text-white"
            >
              <Stack className={"relative"}>
                <LoadingOverlay visible={drivers.isFetching} />
                <Group grow position={"apart"}>
                  <Controller
                    name="routeId"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        className="w-full"
                        searchable
                        disabled={editMode === "detail"}
                        withAsterisk
                        error={errors.routeId && `${errors?.routeId?.message}`}
                        label="Route"
                        placeholder="Select a route"
                        data={parseRoutes()}
                        maxDropdownHeight={400}
                        onKeyUp={debounce((event: any) => {
                          event.target.value
                            ? setRouteCollection({
                                ...routeCollection,
                                skip: 0,
                                search: event.target.value,
                                searchFrom: ["name"],
                              })
                            : setRouteCollection({
                                ...routeCollection,
                                skip: 0,
                                search: "",
                                searchFrom: [],
                              });
                        }, 1000)}
                      />
                    )}
                  />
                </Group>
                <div className="flex flex-col space-y-0">
                  <Title order={6}>Filter drivers</Title>
                  <Group grow position={"apart"}>
                    <Select
                      label="Route Preference"
                      searchable
                      clearable
                      onKeyUp={debounce((event: any) => {
                        event.target.value
                          ? setRouteCollection({
                              ...routeCollection,
                              skip: 0,
                              search: event.target.value,
                              searchFrom: ["name"],
                            })
                          : setRouteCollection({
                              ...routeCollection,
                              skip: 0,
                              search: "",
                              searchFrom: [],
                            });
                      }, 1000)}
                      value={preferredRoute}
                      onChange={(value) => {
                        setPreferredRoute(value);
                        setDriverCollection({
                          ...driverCollection,
                          routeId: value ?? undefined,
                        });
                      }}
                      data={parseRoutes()}
                    />

                    <Select
                      label="Category"
                      value={preferredCategory}
                      clearable
                      onChange={(value) => {
                        setPreferredCategory(value);
                        setDriverCollection({
                          ...driverCollection,
                          categoryId: value ?? undefined,
                        });
                      }}
                      data={parseCategories()}
                    />

                    <Select
                      label="Date Preference"
                      searchable
                      value={preferredDate}
                      clearable
                      onChange={(value) => {
                        setPreferredDate(value);
                        setDriverCollection({
                          ...driverCollection,
                          datePreference: value ?? undefined,
                        });
                      }}
                      data={[
                        { value: "Mon", label: "Monday" },
                        { value: "Tue", label: "Tuesday" },
                        { value: "Wed", label: "Wednesday" },
                        { value: "Thu", label: "Thursday" },
                        { value: "Fri", label: "Friday" },
                        { value: "Sat", label: "Saturday" },
                        { value: "Sun", label: "Sunday" },
                      ]}
                    />
                  </Group>
                </div>

                <Group grow position={"apart"}>
                  <Controller
                    name="driverId"
                    control={control}
                    render={({ field }) => (
                      <Select
                        className="w-full"
                        searchable
                        withAsterisk
                        // itemComponent={SelectItem(cities.isFetching)}
                        error={
                          errors.driverId && `${errors?.driverId?.message}`
                        }
                        label="Driver"
                        placeholder="Select a driver"
                        data={parseDrivers()}
                        maxDropdownHeight={400}
                        {...field}
                        onKeyUp={debounce((event: any) => {
                          event.target.value
                            ? setDriverCollection({
                                ...driverCollection,
                                skip: 0,
                                search: event.target.value,
                                searchFrom: ["name", "phoneNumber"],
                              })
                            : setDriverCollection({
                                ...driverCollection,
                                skip: 0,
                                search: "",
                                searchFrom: [],
                              });
                        }, 1000)}
                      />
                    )}
                  />
                </Group>
                <Controller
                  control={control}
                  name="shift"
                  render={({ field }) => (
                    <Select
                      withAsterisk
                      disabled={!selectedRoute}
                      className="w-full"
                      error={errors.shift && `${errors?.shift?.message}`}
                      label="Shift"
                      placeholder={
                        selectedRoute === null || selectedRoute === undefined
                          ? "Please select route first"
                          : "Select shift"
                      }
                      data={selectedRouteTimeTypes}
                      maxDropdownHeight={400}
                      {...field}
                    />
                  )}
                />

                <Group grow position={"apart"}>
                  {(selectedTimeType === pickupTimeType.Both ||
                    selectedTimeType === pickupTimeType.Morning) && (
                    <Controller
                      name="morningPickupTime"
                      control={control}
                      render={({ field }) => (
                        <Select
                          className="w-full"
                          searchable
                          withAsterisk
                          // itemComponent={SelectItem(cities.isFetching)}
                          error={
                            errors.morningPickupTime &&
                            `${errors?.morningPickupTime?.message}`
                          }
                          label="Morning Pickup Time"
                          placeholder="Select time"
                          data={
                            selectedRoute?.morningPickupTime &&
                            selectedRoute?.morningPickupTime?.length > 0
                              ? selectedRoute?.morningPickupTime?.map(
                                  (time) => ({
                                    value: time,
                                    label: time,
                                  })
                                )
                              : []
                          }
                          maxDropdownHeight={400}
                          {...field}
                        />
                      )}
                    />
                  )}
                  {(selectedTimeType === pickupTimeType.Both ||
                    selectedTimeType === pickupTimeType.Afternoon) && (
                    <Controller
                      name="afternoonPickupTime"
                      control={control}
                      render={({ field }) => (
                        <Select
                          className="w-full"
                          searchable
                          withAsterisk
                          error={
                            errors.afternoonPickupTime &&
                            `${errors?.afternoonPickupTime?.message}`
                          }
                          label="Afternoon Pickup Time"
                          placeholder="Select time"
                          data={
                            selectedRoute?.afternoonPickupTime &&
                            selectedRoute?.afternoonPickupTime?.length > 0
                              ? selectedRoute?.afternoonPickupTime?.map(
                                  (time) => ({
                                    value: time,
                                    label: time,
                                  })
                                )
                              : []
                          }
                          maxDropdownHeight={400}
                          {...field}
                        />
                      )}
                    />
                  )}
                </Group>
                <Group grow position={"apart"}>
                  {/* <Controller
                    name="assignmentWeekDays"
                    control={control}
                    render={({ field }) => (
                      <MultiSelect
                        className="w-full"
                        searchable
                        withAsterisk
                        error={
                          errors.assignmentWeekDays &&
                          `${errors?.assignmentWeekDays?.message}`
                        }
                        label="Trip Days"
                        placeholder="Select days"
                        data={daysOfWeek.map((day) => ({
                          value: day,
                          label: day,
                        }))}
                        maxDropdownHeight={400}
                        {...field}
                      />
                    )}
                  /> */}
                  <Controller
                    control={control}
                    name="assignmentStartDate"
                    render={({ field }) => (
                      <DateInput
                        withAsterisk
                        {...field}
                        className="w-full"
                        error={
                          errors.assignmentStartDate &&
                          `${errors?.assignmentStartDate?.message}`
                        }
                        label="Trip Start Date"
                        placeholder="Select trip start date"
                        // value={field.value ? new Date(field.value) : undefined} // Ensure the value is checked
                        // onChange={(date) => {
                        //   // Handle undefined `date` gracefully
                        //   if (date) {
                        //     field.onChange(date.toISOString());
                        //   } else {
                        //     field.onChange(undefined); // Clear the value if no date is selected
                        //   }
                        // }}
                      />
                    )}
                  />
                </Group>
                <Group grow position={"apart"}>
                  <Controller
                    control={control}
                    name="driverStartDate"
                    render={({ field }) => (
                      <DateInput
                        withAsterisk
                        // classNames={{ label: "dark:text-white" }}
                        {...field}
                        className="w-full"
                        // value={field.value ? field.value : null} // Ensure correct value
                        // onChange={(date) => field.onChange(date)} // Handle onChange
                        error={
                          errors.driverStartDate &&
                          `${errors?.driverStartDate?.message}`
                        }
                        label="Driver's Start Date"
                        placeholder="select driver's start date"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="driverEndDate"
                    render={({ field }) => (
                      <DateInput
                        // classNames={{ label: "dark:text-white" }}
                        {...field}
                        className="w-full"
                        error={
                          errors.driverEndDate &&
                          `${errors?.driverEndDate?.message}`
                        }
                        label="Driver's End Date"
                        placeholder="select driver's end date"
                      />
                    )}
                  />
                </Group>
                <Controller
                  control={control}
                  name="remark"
                  render={({ field }) => (
                    <Textarea
                      classNames={{ label: "dark:text-white" }}
                      className="w-full"
                      error={errors.remark && `${errors?.remark?.message}`}
                      label="Remark"
                      placeholder="Enter remark"
                      {...field}
                    />
                  )}
                />

                <Group position={"right"}>
                  <Button
                    variant="filled"
                    className="text-white bg-primary"
                    type="submit"
                    classNames={{ label: "flex space-x-1" }}
                    size={"xs"}
                    loading={
                      newRouteAssignment.isLoading ||
                      updatedRouteAssignment.isLoading
                    }
                  >
                    <span>
                      <IconDeviceFloppy size={22} strokeWidth={1.5} />
                    </span>
                    <span>{editMode === "new" ? "Submit" : "Update"}</span>
                  </Button>
                </Group>
              </Stack>
            </form>
          </div>
          <div className="overflow-auto" style={{ height: "30rem" }}>
            <Stack className={"relative"}>
              <LoadingOverlay
                visible={drivers.isFetching || routes.isFetching}
              />
              <Stack className={"p-2 border-2"}>
                <Group position={"center"}>
                  <Text fz={"lg"} fw={500}>
                    {selectedRoute?.name ?? "Route"}
                  </Text>
                </Group>
                <Skeleton animate={routes.isFetching} visible={!selectedRoute}>
                  <Group position={"apart"}>
                    <Text
                      fz={"sm"}
                      pl={5}
                      pb={5}
                      className={"w-5/6 text-justify"}
                    >
                      {selectedRoute?.description ??
                        "This is a sample description about the selected route"}
                    </Text>
                    <Text fw={500}>{selectedRoute?.totalDistance} Km</Text>
                  </Group>
                  <Divider mt={5} />
                  <Stack spacing={15} p={5}>
                    <Group position={"apart"}>
                      <Text>City</Text>
                      <Text fw={500}>{selectedRoute?.city?.name ?? "---"}</Text>
                    </Group>
                    {/* <Group position={"apart"}>
                      <Text>Pick up station</Text>
                      <Text fw={500}>{selectedRoute?.pickupName ?? "---"}</Text>
                    </Group>
                    <Group position={"apart"}>
                      <Text>Destination station</Text>
                      <Text fw={500}>
                        {selectedRoute?.destinationName ?? "---"}
                      </Text>
                    </Group> */}
                    <Group position={"apart"}>
                      <Text>Difficulty level</Text>
                      {Levels(selectedRoute?.difficultyLevel ?? "")}
                    </Group>
                    <Group position={"apart"}>
                      <Text>Traffic Jam level</Text>
                      {Levels(selectedRoute?.trafficJamLevel ?? "")}
                    </Group>
                  </Stack>
                </Skeleton>
              </Stack>
              <Stack className={"p-2 border-2"}>
                <Group position={"center"}>
                  <Text fz={"lg"} fw={500}>
                    {selectedDriver?.name ?? "Driver"}
                  </Text>
                </Group>
                <Skeleton
                  animate={drivers.isFetching}
                  visible={!selectedDriver}
                >
                  <Group position={"apart"}>
                    <Avatar
                      radius="xl"
                      size="xl"
                      src={downloadUrlParser({
                        path: selectedDriver?.profileImage?.path,
                        filename: selectedDriver?.profileImage?.filename,
                        originalname:
                          selectedDriver?.profileImage?.originalname,
                        mimetype: selectedDriver?.profileImage?.mimetype,
                      })}
                    />
                    <Rating
                      defaultValue={selectedDriver?.averageRate?.rate ?? 3}
                    />
                  </Group>
                  <Divider mt={10} />
                  <Stack spacing={15} p={5}>
                    <Group position={"apart"}>
                      <Text>Gender</Text>
                      <Text fw={500}>
                        {selectedDriver?.gender
                          ? selectedDriver?.gender === "male"
                            ? "Male"
                            : "Female"
                          : "---"}
                      </Text>
                    </Group>
                    <Group position={"apart"}>
                      <Text>Phone Number</Text>
                      <Text fw={500}>
                        {selectedDriver?.phoneNumber ?? "---"}
                      </Text>
                    </Group>
                    <Group position={"apart"}>
                      <Text>Licence Due Date</Text>
                      <Text fw={500}>
                        {selectedDriver?.licenseDueDate
                          ? moment(selectedDriver?.licenseDueDate).format(
                              "MMMM D, YYYY"
                            )
                          : "---"}
                      </Text>
                    </Group>
                  </Stack>
                  <Divider mt={10} />
                  <Stack spacing={15} p={5}>
                    <Group position={"apart"}>
                      <Text>Vehicle Model</Text>
                      <Text fw={500}>
                        {selectedDriver?.vehicle?.model ?? "---"}
                      </Text>
                    </Group>
                    <Group position={"apart"}>
                      <Text>Code</Text>
                      <Text fw={500}>
                        {selectedDriver?.vehicle?.code ?? "---"}
                      </Text>
                    </Group>
                    <Group position={"apart"}>
                      <Text>Licence Plate Number</Text>
                      <Text fw={500}>
                        {selectedDriver?.vehicle?.plateNumber ?? "---"}
                      </Text>
                    </Group>
                  </Stack>
                  <Divider mt={10} />
                  <Stack spacing={15} p={5}>
                    <Stack align={"start"}>
                      <Text>Date Preferences</Text>
                      <Group
                        position={"center"}
                        className={"border-2 rounded bg-gray-100"}
                        spacing={4}
                        p={4}
                      >
                        {selectedDriver?.datePreferences &&
                        selectedDriver?.datePreferences.length > 0 ? (
                          selectedDriver?.datePreferences.map((item, index) => {
                            return (
                              <Button
                                key={index}
                                className={
                                  "bg-white shadow text-zinc-900 hover:bg-slate-600 hover:text-white"
                                }
                              >
                                {item}
                              </Button>
                            );
                          })
                        ) : (
                          <Button
                            className={
                              "bg-white shadow text-zinc-900 hover:bg-slate-600 hover:text-white"
                            }
                          >
                            No Preference
                          </Button>
                        )}
                      </Group>
                    </Stack>
                    <Stack align={"start"}>
                      <Text>Route Preferences</Text>
                      <Group
                        position={"center"}
                        className={"border-2 rounded bg-gray-100"}
                        spacing={4}
                        p={4}
                      >
                        {selectedDriver?.preferences &&
                        selectedDriver?.preferences.length > 0 ? (
                          selectedDriver?.preferences.map((item, index) => {
                            return (
                              <Button
                                key={index}
                                className={
                                  "bg-white shadow text-zinc-900 hover:bg-slate-600 hover:text-white"
                                }
                              >
                                {item.routeName}
                              </Button>
                            );
                          })
                        ) : (
                          <Button
                            className={
                              "bg-white shadow text-zinc-900 hover:bg-slate-600 hover:text-white"
                            }
                          >
                            No Preference
                          </Button>
                        )}
                      </Group>
                    </Stack>
                  </Stack>
                </Skeleton>
              </Stack>
            </Stack>
          </div>
        </Group>
      </Card>
    </div>
  );
}

export default RouteAssignmentFormComponent;
