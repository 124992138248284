import {
  Anchor,
  Button,
  Group,
  LoadingOverlay,
  Radio,
  Table,
  Text,
} from "@mantine/core";
import {
  IconArrowDownRight,
  IconArrowUpRight,
  IconFileExport,
} from "@tabler/icons";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";

import { lazy, useEffect, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { Owner } from "../../../models/owner.model";
import { decodeMonth } from "../../../shared/constants/Constants";
import { dateFormat } from "../../../shared/utility/date-format/date-format";
import { useLazyGetOwnersQuery } from "../../owner/store/owner.query";
import { StatsRing } from "../component/status-ring";
import {
  useLazyGetBookingsDashboardQuery,
  useLazyGroupOwnersByCreatedDateQuery,
  useLazyGroupOwnersByGenderQuery,
  useLazyGroupOwnersByStatusQuery,
} from "../store/dashboard.query";

const OwnerExportModal = lazy(() => import("../component/owner-export-modal"));

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

interface StatsRingProps {
  data: {
    label: string;
    stats: string;
    progress: number;
    color: string;
    icon: "up" | "down";
  }[];
}
const icons = {
  up: IconArrowUpRight,
  down: IconArrowDownRight,
};

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Vehicles Status",
    },
  },
};

export const donut_options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    labels: {
      position: "right" as const,
    },
    title: {
      display: true,
      text: "Owner Status by Gender",
    },
  },
};

export const bar_options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Registered Owners",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];
const active = [100, 500, 550, 635, 500, 600, 900];
const deactive = [10, 3, 200, 100, 4, 14, 62];

export const data = {
  labels,
  datasets: [
    {
      label: "Active",
      data: labels.map((_, index) => active[index]),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
    {
      label: "Inactive",
      data: labels.map((_, deactiveIndex) => deactive[deactiveIndex]),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ],
};

export default function OwnerDashboardPage() {
  const [createdDateFormat, setCreatedDateFormat] = useState("month");
  const [openExport, setOpenExport] = useState<boolean>(false);
  const [donut_state_data, setDonutData] = useState<any>();
  const [bar_state_data, setBarData] = useState<any>();
  const [passengersCircleProgress, setOwnersCircleProgress] = useState<any[]>(
    []
  );
  const [getOwners, owners] = useLazyGetOwnersQuery();
  const [groupOwnersByGender, genderGroupedOwners] =
    useLazyGroupOwnersByGenderQuery();
  const [groupOwnersByStatus, groupedOwners] =
    useLazyGroupOwnersByStatusQuery();
  const [GroupOwnersByCreatedDate, createdDateResponse] =
    useLazyGroupOwnersByCreatedDateQuery();
  const [getBookings, bookings] = useLazyGetBookingsDashboardQuery();

  useEffect(() => {
    getOwners({
      skip: 0,
      top: 6,
      orderBy: [{ field: "createdAt", direction: "desc" }],
    }).then((ownerResponse) => {
      groupOwnersByStatus({
        groupBy: ["enabled"],
      }).then((response) => {
        if (response?.data && ownerResponse?.data) {
          let total = ownerResponse?.data?.count ?? 1;

          const data = response.data.map((item: any) => {
            return {
              label: item.status ? "Active" : "Inactive",
              stats: item.count,
              progress: (item.count / total) * 100,
              color: item.status ? "green" : "red",
              icon: item.status ? "up" : "down",
            };
          });
          setOwnersCircleProgress(data);
        }
      });
    });

    let labels: string[];
    let data: number[];
    async function getNewOwnersEveryDate() {
      const response = await GroupOwnersByCreatedDate(createdDateFormat);
      if (response?.data) {
        labels = response.data.map((item: any) =>
          createdDateFormat === "month"
            ? decodeMonth(item.createdAt)
            : createdDateFormat === "year"
            ? item.createdAt
            : item.createdAt
        );
        data = response.data.map((item: any) => item.count);
      }
      const bar_data = {
        labels,
        datasets: [
          {
            label: "Owners",
            data: data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 159, 64, 0.2)",
              "rgba(255, 205, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(201, 203, 207, 0.2)",
            ],
            borderColor: [
              "rgb(255, 99, 132)",
              "rgb(255, 159, 64)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(54, 162, 235)",
              "rgb(153, 102, 255)",
              "rgb(201, 203, 207)",
            ],
            borderWidth: 1,
          },
        ],
      };

      setBarData(bar_data);
    }
    getNewOwnersEveryDate();
  }, [createdDateFormat]);

  useEffect(() => {
    let labels: any[] = [];
    let data: any[] = [];

    async function getVehiclesByCategory() {
      const response = await groupOwnersByGender({ groupBy: ["gender"] });
      if (response.data) {
        labels = response.data.map((item: any) => {
          return item.gender;
        });
        data = response.data.map((item: any) => {
          return item.count;
        });
      }

      const donut_data = {
        labels: labels,
        datasets: [
          {
            label: "Owners",
            data: data ?? [0],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      };
      setDonutData(donut_data);
    }
    getVehiclesByCategory();
  }, []);

  return (
    <div className="w-full p-4">
      <div className="flex items-center justify-between w-full h-16 px-2 mt-2 mb-2 border border-sky-300">
        <div className="flex-col space-y-2">
          <div className="font-semibold capitalize text-primary">
            Owners dashboard report
          </div>
          <div className="font-semibold capitalize text-primary">
            {"Total: "}
            {owners?.data?.count}
          </div>
        </div>

        <div className="flex space-x-4">
          <Button
            leftIcon={<IconFileExport color="gray" />}
            size="sm"
            variant="default"
            onClick={() => setOpenExport(true)}
          >
            Export
          </Button>
        </div>
      </div>
      <div className="flex justify-between space-x-2">
        <div className="w-full p-2 border rounded shadow shadow-sky-100">
          <div className="flex justify-between px-2 mb-2">
            <Text className="text-sm text-gray-600 text-semibold">
              Owner status
            </Text>
            <Text className="text-sm text-primary text-semibold">
              {"Total: "}
              {owners?.data?.count}
            </Text>
          </div>
          {groupedOwners?.data && <StatsRing data={passengersCircleProgress} />}
          <div className="w-full p-2 mt-2 border rounded ">
            <Table verticalSpacing="xs">
              <thead>
                <tr className="text-sm leading-5">
                  <td>Name</td>
                  <td>Phone</td>
                  <td>Gender</td>
                  <td>Registered At</td>
                </tr>
              </thead>
              <tbody>
                {owners.isLoading ? (
                  <td
                    colSpan={5}
                    className="items-center justify-center w-full"
                  >
                    <LoadingOverlay visible />
                  </td>
                ) : (
                  owners?.data?.data?.map((owner: Owner) => (
                    <tr className="z-20 text-xs cursor-pointer hover:shadow">
                      <td className="text-xs">
                        <Anchor<"a">
                          size="sm"
                          onClick={(event) => event.preventDefault()}
                        >
                          {owner.name}
                        </Anchor>
                      </td>
                      <td className="text-xs">{owner.phoneNumber}</td>
                      <td className="text-xs">
                        {owner.isCompany ? "Company" : owner.gender}
                      </td>
                      <td className="text-xs">{dateFormat(owner.createdAt)}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <div className="flex justify-center w-full p-2 border rounded shadow h-80 shadow-sky-100">
          {genderGroupedOwners?.isLoading ? (
            <LoadingOverlay visible />
          ) : (
            donut_state_data && (
              <Doughnut options={donut_options} data={donut_state_data} />
            )
          )}
        </div>
      </div>
      <div className="flex-col justify-between p-2 mt-5 space-y-4 border rounded shadow">
        <div className="flex space-x-12 ">
          {" "}
          <Radio.Group
            // label="Date Format"
            defaultValue={createdDateFormat}
            onChange={(e) => {
              setCreatedDateFormat(e);
            }}
          >
            <Group mt="xs">
              <Radio value="month" label="Monthly" />
              <Radio value="year" label="Yearly" />
            </Group>
          </Radio.Group>
        </div>
        <div className="flex justify-between mt-10 space-x-2 shadow">
          <div className="flex justify-center w-full p-2 border rounded shadow-lg h-92 shadow-sky-100">
            {createdDateResponse?.isLoading ? (
              <LoadingOverlay visible />
            ) : (
              bar_state_data && (
                <Bar options={bar_options} data={bar_state_data} />
              )
            )}
          </div>
        </div>
      </div>

      {openExport && (
        <OwnerExportModal
          open={openExport}
          onClose={() => setOpenExport(false)}
        />
      )}
    </div>
  );
}
