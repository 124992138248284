import { Badge, Button, LoadingOverlay, Table } from "@mantine/core";
import { IconArrowBack } from "@tabler/icons";

import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../../shared/component/Card/card-component";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { dateFormat } from "../../../shared/utility/date-format/date-format";
import { NewBookingStatus } from "../../../shared/utility/enum/other.enum";
import { useLazyGetBookingListsQuery } from "../../booking/store/booking.query";
export default function AssignmentBookingsComponent() {
  const params = useParams();
  const navigate = useNavigate();
  const [getBookings, bookings] = useLazyGetBookingListsQuery();

  const rows = bookings?.data?.data?.map((element) => (
    <tr key={element.id}>
      <td>{element.passengerName}</td>

      <td>{element.passengerPhoneNumber}</td>
      <td>
        {element?.shift
          ? element?.shift?.charAt(0).toUpperCase() + element.shift.slice(1)
          : ""}
      </td>
      <td>{element?.morningPickupTime || "---"}</td>
      <td>{element?.afternoonPickupTime || "---"}</td>
      <td>{element?.pickupName}</td>
      <td>{element?.destinationName}</td>
      <td> {dateFormat(element?.createdAt)}</td>
      <td>
        <Badge
          pl={0}
          className=""
          color={
            element?.status === "Created"
              ? "blue"
              : element?.status === "Boarded"
              ? "yellow"
              : element?.startingAt === "Arrived"
              ? "green"
              : element?.status === "Missed"
              ? "dark"
              : element?.status === "Cancelled"
              ? "red"
              : "gray"
          }
          radius="xl"
        >
          {element?.status === NewBookingStatus.CREATED
            ? "Pending"
            : element?.status}
        </Badge>
      </td>
    </tr>
  ));

  useEffect(() => {
    getBookings({
      filter: [
        [{ field: "assignmentId", value: `${params?.id}`, operator: "=" }],
        [
          {
            field: "status",
            value: NewBookingStatus.InitialFeePaid,
            operator: "=",
          },
          {
            field: "status",
            value: NewBookingStatus.BOOKED,
            operator: "=",
          },
        ],
      ],
      orderBy: [{ field: "createdAt", direction: "desc" }],
    });
  }, [params?.id]);
  return (
    <div className="relative flex-col m-4 space-y-4 border">
      <Card
        titleParentClassNames="w-full"
        title={
          <div className="flex items-center justify-between w-full p-2 border-b">
            <div>Bookings</div>
            <div>
              <Button
                size="xs"
                onClick={() => navigate(-1)}
                className="text-white bg-primary"
                leftIcon={<IconArrowBack size={10} />}
              >
                Back
              </Button>
            </div>
          </div>
        }
      >
        <LoadingOverlay visible={bookings?.isLoading || bookings?.isFetching} />

        <div className="h-full px-10 py-4">
          {bookings?.data?.count ?? 0 > 0 ? (
            <Table fontSize={14} withBorder className="rounded-lg">
              <thead>
                <tr>
                  <th>Passenger Name</th>
                  <th>Passenger Phone</th>
                  <th>Shift</th>
                  <th>Morning Pickup Time</th>
                  <th>Afternoon Pickup Time</th>
                  <th>Pickup</th>
                  <th>Destination</th>
                  <th>Booked At</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          ) : (
            <EmptyIcon />
          )}
        </div>
      </Card>
    </div>
  );
}
