/* eslint-disable @typescript-eslint/no-explicit-any */
import { Configuration } from "../../../models/configuration.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { appApi } from "../../../store/app.api";
import { CONFIGURATION_ENDPOINT } from "../configuration.endpoints";

const accountQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getConfiguration: builder.query<Configuration, any>({
      query: (data?: any) => ({
        url: `${CONFIGURATION_ENDPOINT.get_configurations}`,
        method: "get",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    updateConfiguration: builder.mutation<Configuration, Configuration>({
      // @ts-ignore
      query: (data: Configuration) => ({
        url: `${CONFIGURATION_ENDPOINT.update_configuration}`,
        method: "PUT",
        data: data,
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          notification("success", "Configuration updated successfully");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const { useUpdateConfigurationMutation, useLazyGetConfigurationQuery } =
  accountQuery;
