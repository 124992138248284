import { Button, Card, Tooltip } from "@mantine/core";
import { IconReceiptDollar } from "@tabler/icons-react";
import { lazy, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { EditCircle, FileExport } from "tabler-icons-react";
import { CollectionQuery, Order } from "../../../models/collection.model";
import { DriverFee } from "../../../models/driver-fee.model";
import {
  EntityConfig,
  entityViewMode,
} from "../../../models/entity-config.model";
import EntityList from "../../../shared/component/entity-list/entity-list.component";
import {
  useAddAssignmentPaymentMutation,
  useDriversFeeStatusUpdateMutation,
  useLazyGetDriverFeesQuery,
} from "../store/route-assignment.query";
import PaymentExportModal from "./export-modal-component";
import PayOutModalComponent from "./pay-out-modal-component ";

const PaymentStatusModalComponent = lazy(
  () => import("../sub-component/payment-status-modal-component")
);
const MultiplePaymentStatusModalComponent = lazy(
  () => import("../sub-component/multiple-payment-status-modal-component")
);
export default function AssignmentPaymentHistoryComponent() {
  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: ["driver", "driver.bankAccounts"],
    orderBy: [{ field: "createdAt", direction: "desc" }],
  });

  const [selectedItems, setSelectedItems] = useState<DriverFee[]>([]);

  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [openMultipleStatusModal, setOpenMultipleStatusModal] = useState(false);
  const [openPayOutModal, setOpenPayOutModal] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [getDriverFees, driverFees] = useLazyGetDriverFeesQuery();
  const [updatePaymentStatus, updatePaymentStatusResult] =
    useDriversFeeStatusUpdateMutation();
  const [createPaymentForAssignment, paymentResponse] =
    useAddAssignmentPaymentMutation();

  useEffect(() => {
    getDriverFees(collection);
  }, [collection]);

  const params = useParams();
  const location = useLocation();
  const [viewMode, setViewMode] = useState<entityViewMode>("list");

  useEffect(() => {
    if (params?.id !== undefined) {
      setViewMode("detail");
    } else {
      setViewMode("list");
    }
  }, [setViewMode, params?.id, location]);

  const data: DriverFee[] | undefined = driverFees.data?.data;
  const config: EntityConfig<DriverFee> = {
    primaryColumn: { key: ["driver", "name"], name: "Driver" },
    rootUrl: "/driver-payments",
    identity: "id",
    name: "Driver",
    visibleColumn: [
      {
        key: ["driver", "name"],
        name: "Driver",
        hideSort: true,
        render: (driverFee) => {
          return (
            <div>
              {driverFee?.driver?.name}{" "}
              {"(" + driverFee?.driver?.phoneNumber + ")"}
            </div>
          );
        },
      },

      {
        key: "fee",
        name: "Fee",
        render(value) {
          return value.fee.toFixed(2) + " ETB";
        },
      },

      {
        key: "paymentMethod",
        name: "Payment Method",
        hideSort: true,
        render(value) {
          const paymentMethod = value?.paymentMethod || "Unknown";
          const bankName = value?.bankName || "";

          return paymentMethod === "Bank"
            ? `${paymentMethod} ${bankName && "-"} ${bankName}`
            : paymentMethod;
        },
      },

      { key: "transactionNumber", name: "Transaction No.", hideSort: true },
      {
        key: "paymentStatus",
        name: "Status",
        hideSort: true,
        render: (driverFee) => {
          return (
            <div className="text-gray-900 dark:text-white">
              {driverFee?.paymentStatus}
            </div>
          );
        },
      },
      { key: "createdAt", name: "Completed On", isDate: true },
    ],
  };
  const onPayout = (data: any) => {
    createPaymentForAssignment(data).then((response: any) => {
      if (response?.data?.length > 0) {
        setOpenPayOutModal(false);
      }
    });
  };

  const onPaymnetStatusUpdate = (data: any) => {
    const driverFeeIds = selectedItems?.map((driverFee) => {
      return driverFee?.id;
    });
    updatePaymentStatus({
      driverFeeId: driverFeeIds[0],
      paymentStatus: data?.paymentStatus,
      paymentMethod: data?.paymentMethod,
      bankName: data?.bankName,
      transactionNumber: data?.transaction_number ?? null,
    }).then((response: any) => {
      if (response?.data) {
        setOpenStatusModal(false);
      }
    });
  };

  const onItemsSelected = (items: DriverFee[]) => {
    setSelectedItems(items);
  };
  const header = (
    <div className="flex justify-end w-full space-x-1">
      {selectedItems?.length > 0 && (
        <Tooltip label="Update Payment Status">
          <Button
            className="text-white bg-primary"
            onClick={() => {
              setOpenMultipleStatusModal(true);
            }}
            size="xs"
          >
            <EditCircle />
          </Button>
        </Tooltip>
      )}

      <Tooltip label="New Pay Out">
        <Button
          className="text-white bg-primary"
          onClick={() => {
            setOpenPayOutModal(true);
          }}
          size="xs"
        >
          <IconReceiptDollar />
        </Button>
      </Tooltip>
      <Tooltip label="Advanced Export">
        <Button
          className="flex items-center justify-center text-white bg-primary"
          size="xs"
          onClick={() => {
            setOpenExportModal(true);
          }}
        >
          <FileExport />
        </Button>
      </Tooltip>
    </div>
  );
  return (
    <div className="h-full ">
      <Card title={"Driver's Payment History"}>
        {" "}
        <Card.Section className="ml-2 font-bold">
          Driver's Payment History
        </Card.Section>
        <EntityList
          viewMode={viewMode}
          showExport={viewMode === "list"}
          showSelector={viewMode === "list"}
          title={"Payment History"}
          showArchivedCheckBox={false}
          showNewButton={false}
          header={viewMode !== "detail" ? header : <></>}
          firstChildStyle={viewMode === "list" ? "border-0" : "border"}
          total={driverFees?.data?.count}
          itemsLoading={driverFees.isFetching}
          config={config}
          items={data}
          initialPage={1}
          defaultPageSize={10}
          pageSize={[10, 15, 20]}
          collectionQuery={collection}
          onItemsSelected={(item) => {
            onItemsSelected(item);
          }}
          onPaginationChange={(skip: number, top: number) => {
            if (collection.skip !== skip || collection.top !== top) {
              setCollection({ ...collection, skip: skip, top: top });
            }
          }}
          onSearch={(data: any) => {
            setCollection({
              ...collection,
              search: data,
              searchFrom: ["driver.name", "driver.phoneNumber"],
            });
          }}
          onFilterChange={(data: any) =>
            setCollection({ ...collection, filter: data })
          }
          onOrder={(data: Order) =>
            setCollection({ ...collection, orderBy: [data] })
          }
        />
        {openStatusModal && (
          <PaymentStatusModalComponent
            data={{ status: selectedItems?.[0].paymentStatus ?? "" }}
            isLoading={updatePaymentStatusResult?.isLoading}
            opened={openStatusModal}
            close={() => setOpenStatusModal(false)}
            submit={(data) => onPaymnetStatusUpdate(data)}
          />
        )}
        {openMultipleStatusModal && (
          <MultiplePaymentStatusModalComponent
            driverFeeIds={
              selectedItems?.map((item) => {
                return item.id;
              }) ?? []
            }
            opened={openMultipleStatusModal}
            close={() => setOpenMultipleStatusModal(false)}
          />
        )}
        {openPayOutModal && (
          <PayOutModalComponent
            // data={{ status: selectedItems?.[0].paymentStatus ?? "" }}
            isLoading={paymentResponse?.isLoading}
            opened={openPayOutModal}
            close={() => setOpenPayOutModal(false)}
            submit={(data) => {
              onPayout(data);
              setOpenPayOutModal(false);
            }}
          />
        )}
        {openExportModal && (
          <PaymentExportModal
            // data={{ status: selectedItems?.[0].paymentStatus ?? "" }}
            // isLoading={paymentResponse?.isLoading}
            open={openExportModal}
            onClose={() => setOpenExportModal(false)}
            // submit={(data) => onPayout(data)}
          />
        )}
      </Card>
    </div>
  );
}
