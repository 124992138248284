/* eslint-disable @typescript-eslint/no-explicit-any */
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { Kid } from "../../../models/kids.model";
import { Parent } from "../../../models/parent.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { PARENT_ENDPOINT } from "../parent.endpoint";

interface CollectionId {
  id?: string;
  collection: CollectionQuery;
}

let parentCollection: CollectionQuery;
let kidsCollection: CollectionId;
let kidCollection: CollectionQuery;
let archivedParentCollection: CollectionQuery;
const parentQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getParent: builder.query<Parent, string>({
      query: (id: string) => ({
        url: `${PARENT_ENDPOINT.detail}/${id}`,
        method: "get",
      }),
    }),

    getArchivedParent: builder.query<Parent, string>({
      query: (id: string) => ({
        url: `${PARENT_ENDPOINT.archivedParent}/${id}`,
        method: "get",
      }),
    }),

    getArchivedParents: builder.query<Collection<Parent>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${PARENT_ENDPOINT.archivedList}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            archivedParentCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getParents: builder.query<Collection<Parent>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${PARENT_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            parentCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getKids: builder.query<Collection<Kid>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${PARENT_ENDPOINT.getKids}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            kidCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getParentKids: builder.query<Collection<Kid>, CollectionId>({
      query: (data: CollectionId) => ({
        url: `${PARENT_ENDPOINT.getParentKids}/${data.id}`,
        method: "GET",
        params: collectionQueryBuilder(data.collection),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            kidsCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    createParent: builder.mutation<Parent, Parent>({
      query: (newData: any) => ({
        url: `${PARENT_ENDPOINT.create}`,
        method: "post",
        data: newData,
        permission: "manage-parents",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully created");
            dispatch(
              parentQuery.util.updateQueryData(
                "getParents",
                parentCollection,
                (draft) => {
                  if (data) {
                    draft.data.unshift(data);
                    draft.count += 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    createParentPreference: builder.mutation<
      any,
      { days?: string[]; driverGender?: string; parentId?: string }
    >({
      query: (newData: {
        days?: string[];
        driverGender?: string;
        parentId?: string;
      }) => ({
        url: `${PARENT_ENDPOINT.createParentPreference}`,
        method: "post",
        data: newData,
        permission: "manage-parents",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully created");
            dispatch(
              parentQuery.util.updateQueryData(
                "getParents",
                parentCollection,
                (draft) => {
                  draft.data = draft.data.map((parent: Parent) => {
                    if (parent.id == data.parentId) {
                      return {
                        ...parent,
                        parentPreferences: [{ days: data?.days }],
                      };
                    }
                    return parent;
                  });
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    estimateKidFee: builder.mutation<any, any>({
      query: (newData: any) => ({
        url: `${PARENT_ENDPOINT.estimateKidFee}`,
        method: "post",
        data: newData,
        permission: "manage-parents",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            //   dispatch(
            //     parentQuery.util.updateQueryData(
            //       "getParentKids",
            //       kidsCollection,
            //       (draft) => {
            //         if (data) {
            //           draft.data = draft?.data?.map((kid) => {
            //             if (kid.id === data.id) return data;
            //             else {
            //               return kid;
            //             }
            //           });
            //         }
            //       }
            //     )
            //   );
            //   dispatch(
            //     parentQuery.util.updateQueryData(
            //       "getParent",
            //       param.parentId ?? "",
            //       (draft) => {
            //         draft.status = data.status;
            //       }
            //     )
            //   );
            // notification(
            //   "success",
            //   "Successfully Processed the manual Payment"
            // );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    payManually: builder.mutation<any, { amount: number; parentId: string }>({
      query: (newData: { amount: number; parentId: string }) => ({
        url: `${PARENT_ENDPOINT.payManually}`,
        method: "post",
        data: newData,
        permission: "manage-parents",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              parentQuery.util.updateQueryData(
                "getParentKids",
                kidsCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((kid) => {
                      if (kid.id === data.id) return data;
                      else {
                        return kid;
                      }
                    });
                  }
                }
              )
            );

            dispatch(
              parentQuery.util.updateQueryData(
                "getParent",
                param.parentId ?? "",
                (draft) => {
                  draft.status = data.status;
                }
              )
            );
            notification(
              "success",
              "Successfully Processed the manual Payment"
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    createKid: builder.mutation<Kid, Kid>({
      query: (newData: any) => ({
        url: `${PARENT_ENDPOINT.createKid}`,
        method: "post",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              parentQuery.util.updateQueryData(
                "getParentKids",
                kidsCollection,
                (draft) => {
                  if (data) {
                    draft.data.unshift(data);
                    draft.count += 1;
                  }
                }
              )
            );
            notification("success", "Successfully created");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updateParent: builder.mutation<Parent, Parent>({
      query: (newData: any) => ({
        url: `${PARENT_ENDPOINT.update}`,
        method: "put",
        data: newData,
        permission: "manage-parents",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              parentQuery.util.updateQueryData(
                "getParents",
                parentCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((user) => {
                      if (user.id === data.id) return data;
                      else {
                        return user;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    changeStatus: builder.mutation<Parent, { id: string; status: string }>({
      query: (newData: { id: string; status: string }) => ({
        url: `${PARENT_ENDPOINT.changeStatus}`,
        method: "post",
        data: newData,
        // permission:'manage-parents'
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              parentQuery.util.updateQueryData(
                "getParents",
                parentCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((user) => {
                      if (user.id === data.id) return data;
                      else {
                        return user;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updateKid: builder.mutation<Kid, Kid>({
      query: (newData: any) => ({
        url: `${PARENT_ENDPOINT.updateKid}`,
        method: "put",
        data: newData,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              parentQuery.util.updateQueryData(
                "getParentKids",
                kidsCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((kid) => {
                      if (kid.id === data.id) return data;
                      else {
                        return kid;
                      }
                    });
                  }
                }
              )
            );
            notification("success", "Successfully updated");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updateKidProfile: builder.mutation<Kid, any>({
      query: (newData: any) => ({
        url: `${PARENT_ENDPOINT.updateKidProfile}/` + newData.id,
        method: "post",
        data: newData.data,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              parentQuery.util.updateQueryData(
                "getParentKids",
                kidsCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((user) => {
                      if (user.id === data.id) return data;
                      else {
                        return user;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    activateParent: builder.mutation<Parent, string>({
      query: (id: string) => ({
        url: `${PARENT_ENDPOINT.toggleStatus}/${id}`,
        method: "post",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated status");
            dispatch(
              parentQuery.util.updateQueryData(
                "getParents",
                parentCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((user) => {
                      if (user.id === data.id) return data;
                      else {
                        return user;
                      }
                    });
                  }
                }
              )
            );
            dispatch(
              parentQuery.util.updateQueryData("getParent", param, (draft) => {
                draft.enabled = data.enabled;
              })
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    archiveParent: builder.mutation<Parent, { id: string; reason: string }>({
      query: (data) => ({
        url: `${PARENT_ENDPOINT.archive}`,
        method: "delete",
        data: data,
        permission: "activate-or-block-parents",
      }),

      async onQueryStarted(query, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              parentQuery.util.updateQueryData(
                "getParents",
                parentCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.map((parent) => {
                      if (parent.id === query.id) {
                        return data;
                      } else {
                        return parent;
                      }
                    });
                  }
                }
              )
            );
            dispatch(
              parentQuery.util.updateQueryData(
                "getParent",
                query?.id,
                (draft) => {
                  if (data) {
                    draft.deletedAt = data.deletedAt;
                  }
                }
              )
            );
            dispatch(
              parentQuery.util.updateQueryData(
                "getArchivedParent",
                query?.id,
                (draft) => {
                  if (data) {
                    draft.deletedAt = data.deletedAt;
                  }
                }
              )
            );
            notification("success", "Successfully archived");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    archiveKid: builder.mutation<
      boolean,
      { id: string; parentId: string; reason: string }
    >({
      query: (data) => ({
        url: `${PARENT_ENDPOINT.archiveKid}`,
        method: "delete",
        data: data,
      }),

      async onQueryStarted(query, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully archived");
            dispatch(
              parentQuery.util.updateQueryData(
                "getParentKids",
                kidsCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter(
                      (user) => user.id !== query.id
                    );
                    draft.count -= 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    deleteKid: builder.mutation<boolean, { id: string; parentId: string }>({
      query: (data) => ({
        url: `${PARENT_ENDPOINT.deleteKid}`,
        method: "delete",
        data: data,
      }),

      async onQueryStarted(query, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              parentQuery.util.updateQueryData(
                "getParentKids",
                kidsCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter(
                      (user) => user.id !== query.id
                    );
                    draft.count -= 1;
                  }
                }
              )
            );

            // dispatch(
            //   parentQuery.util.updateQueryData(
            //     "getArchivedParents",
            //     archivedParentCollection,
            //     (draft) => {
            //       if (data) {
            //         draft.data = draft.data.filter((user) => user.id !== id);
            //         draft.count -= 1;
            //       }
            //     }
            //   )
            // );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    deleteParent: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${PARENT_ENDPOINT.delete}/${id}`,
        method: "delete",
        permission: "manage-parents",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              parentQuery.util.updateQueryData(
                "getArchivedParents",
                archivedParentCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter((user) => user.id !== id);
                    draft.count -= 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    restoreParent: builder.mutation<Parent, string>({
      query: (id: string) => ({
        url: `${PARENT_ENDPOINT.restore}/${id}`,
        method: "post",
        permission: "manage-parents",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              parentQuery.util.updateQueryData(
                "getParents",
                parentCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.map((parent) => {
                      if (parent.id === id) {
                        return data;
                      } else {
                        return parent;
                      }
                    });
                  }
                }
              )
            );
            dispatch(
              parentQuery.util.updateQueryData("getParent", id, (draft) => {
                if (data) {
                  draft.deletedAt = data.deletedAt;
                }
              })
            );
            dispatch(
              parentQuery.util.updateQueryData(
                "getArchivedParent",
                id,
                (draft) => {
                  if (data) {
                    draft.deletedAt = data.deletedAt;
                  }
                }
              )
            );
            notification("success", "Successfully restored");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    restoreKid: builder.mutation<Kid, { id: string; parentId: string }>({
      query: (data) => ({
        url: `${PARENT_ENDPOINT.restoreKid}`,
        method: "post",
        permission: "manage-parents",
        data: data,
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              parentQuery.util.updateQueryData(
                "getParentKids",
                kidsCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.map((kid) => {
                      if (kid.id === param.id) {
                        return data;
                      } else {
                        return kid;
                      }
                    });
                  }
                }
              )
            );
            // dispatch(
            //   parentQuery.util.updateQueryData("getKid", param.id, (draft) => {
            //     if (data) {
            //       draft.deletedAt = data.deletedAt;
            //     }
            //   })
            // );
            // dispatch(
            //   parentQuery.util.updateQueryData(
            //     "getArchivedParent",
            //     id,
            //     (draft) => {
            //       if (data) {
            //         draft.deletedAt = data.deletedAt;
            //       }
            //     }
            //   )
            // );
            notification("success", "Successfully restored");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetParentQuery,
  useLazyGetParentsQuery,
  useLazyGetArchivedParentQuery,
  useLazyGetArchivedParentsQuery,
  useLazyGetParentKidsQuery,
  useLazyGetKidsQuery,

  useActivateParentMutation,
  useArchiveParentMutation,
  useCreateParentMutation,
  useDeleteParentMutation,
  useRestoreParentMutation,
  useUpdateParentMutation,

  useCreateKidMutation,
  useUpdateKidMutation,
  useArchiveKidMutation,
  useRestoreKidMutation,
  useUpdateKidProfileMutation,
  useChangeStatusMutation,
  usePayManuallyMutation,

  useDeleteKidMutation,

  useEstimateKidFeeMutation,
  useCreateParentPreferenceMutation,
} = parentQuery;
