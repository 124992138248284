import {
  Button,
  LoadingOverlay,
  Modal,
  MultiSelect,
  Select,
} from "@mantine/core";
import { YearPickerInput } from "@mantine/dates";
import { IconFileExport } from "@tabler/icons";
import FileSaver from "file-saver";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { CollectionQuery, Filter } from "../../../models/collection.model";
import { DriverFor } from "../../../models/provider.model";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { monthsList } from "../../../shared/constants/Constants";
import { useLazyGetProvidersQuery } from "../../provider/store/provider.query";
import { useLazyGetRoutesQuery } from "../../route/store/route.query";
import { useLazyExportPayOutsQuery } from "../store/route-assignment.query";

interface Props {
  onClose: () => void;
  open: boolean;
}

let schema = yup.object().shape({
  owner: yup.string(),
  active: yup.boolean(),
});

const defaultValue = {
  id: "",
  title: "",
  description: "",
  coverImage: undefined,
};

export default function PaymentExportModal(props: Props) {
  const { open, onClose } = props;

  const [value, setDateRangeValue] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [validationError, setValidationError] = useState<string>("");
  const [filterRoute, setFilterRoute] = useState<Filter[]>([]);
  const [filterDriver, setFilterDriver] = useState<Filter[]>([]);
  const [filterMonth, setFilterMonth] = useState<Filter[]>([]);
  const [filterYear, setFilterYear] = useState<Filter[]>([]);
  const [filterValue, setFilterValue] = useState<string[]>([]);

  const [collectionQuery, setCollectionQuery] = useState<CollectionQuery>({
    skip: 0,
    orderBy: [{ field: "createdAt", direction: "asc" }],
    includes: ["driver"],
  });

  const [getRoutes, routes] = useLazyGetRoutesQuery();
  const [getDrivers, drivers] = useLazyGetProvidersQuery();
  const [exportPayouts, exportedPayouts] = useLazyExportPayOutsQuery();
  const [routeCollection, setRouteCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });
  const [driverCollection, setDriverCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });

  const filterQuery = (data: string[]) => {
    let filterQueryValue: any[] = [];
    const filterMap: { [key: string]: any[] } = {};
    data.forEach((item) => {
      filterMap[JSON.parse(item)?.field] = data.filter(
        (query) => JSON.parse(query)?.field === JSON.parse(item).field
      );
    });
    // constructs the filter query into array form the grouped object
    Object.keys(filterMap).forEach((key) => {
      filterQueryValue = [
        ...filterQueryValue,
        filterMap[key].map((item) => JSON.parse(item)),
      ];
    });
    return filterQueryValue;
  };

  const onExport = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8;";
    if (filterMonth.length < 1) {
      setValidationError("Please select a month");
    } else {
      setValidationError("");
      exportPayouts(collectionQuery).then((result) => {
        if (result?.data) {
          const data = new Blob([result?.data], { type: fileType });
          FileSaver.saveAs(data, "payouts" + ".xlsx");
        }
      });
    }
  };

  useEffect(() => {
    getRoutes({
      ...routeCollection,
      filter: [[{ field: "isActive", value: true, operator: "=" }]],
      includes: ["stations"],
    });
  }, [routeCollection]);

  useEffect(() => {
    getDrivers({
      ...driverCollection,
      includes: ["preferences"],
      filter: [
        [{ field: "vehicleId", value: "", operator: "notNull" }],
        [{ field: "enabled", value: true, operator: "=" }],
        [{ field: "driverFor", value: DriverFor.KabbaKids, operator: "!=" }],
      ],
    });
  }, [driverCollection]);

  useEffect(() => {
    let filter = filterQuery(filterValue);

    if (filterDriver.length > 0) {
      filter = [...filter, filterDriver];
    }
    if (filterMonth.length > 0) {
      filter = [...filter, filterMonth];
    }
    if (filterYear.length > 0) {
      filter = [...filter, filterYear];
    }

    setCollectionQuery({ ...collectionQuery, filter: filter });
  }, [filterValue, filterDriver, filterMonth, filterYear]);

  return (
    <Modal
      size={500}
      opened={open}
      className="h-fit"
      centered
      onClose={onClose}
      title="Export"
    >
      <>
        <div className="flex justify-between w-full mb-4 space-x-4">
          <YearPickerInput
            className="w-full mb-2"
            label="Year"
            withAsterisk
            defaultValue={new Date()}
            onChange={(year) => {
              setFilterYear([
                {
                  field: "paymentYear",
                  value: year instanceof Date ? year.getFullYear() : year,

                  operator: "=",
                },
              ]);
            }}
          />
          <div className="w-full">
            <Select
              label="Month"
              withAsterisk
              searchable
              className="w-full mb-2"
              nothingFound={
                <div className="items-center justify-center w-full fle">
                  <EmptyIcon />
                </div>
              }
              onChange={(month) => {
                setFilterMonth([
                  {
                    field: "paymentMonth",
                    value: Number(month),
                    // name: month,
                    operator: "=",
                  },
                ]);
                setValidationError("");
              }}
              placeholder="Filter by month"
              data={monthsList}
            />
            {validationError && (
              <span className="-mt-2 text-sm text-red-500">
                {validationError}
              </span>
            )}
          </div>
        </div>

        <div className="flex my-4">
          <MultiSelect
            label="Driver"
            zIndex={100}
            dropdownPosition="bottom"
            searchable
            className="w-full mb-2"
            nothingFound={
              <div className="items-center justify-center w-full fle">
                <EmptyIcon />
              </div>
            }
            onKeyUp={debounce((event: any) => {
              event.target.value
                ? setDriverCollection({
                    ...driverCollection,
                    skip: 0,
                    search: event.target.value,
                    searchFrom: ["name", "phoneNumber"],
                  })
                : setDriverCollection({
                    ...driverCollection,
                    skip: 0,
                    search: "",
                    searchFrom: [],
                  });
            }, 1000)}
            onChange={(data) => {
              setFilterDriver(
                data?.map((driver) => {
                  return {
                    field: "driverId",
                    value: driver,
                    operator: "=",
                  };
                })
              );
            }}
            placeholder="Filter by driver"
            data={
              drivers?.data?.data?.map((item: any) => ({
                label: `${item.name}(${item.phoneNumber})`,
                value: item.id,
              })) ?? []
            }
          />
        </div>
        <div className="flex space-x-4">
          <LoadingOverlay
            visible={
              drivers?.isLoading ||
              drivers?.isFetching ||
              routes?.isLoading ||
              routes?.isFetching
            }
            overlayBlur={2}
          />
          {/* <Checkbox.Group
            size="xs"
            className="flex justify-center w-full space-x-4"
            withAsterisk
            defaultValue={filterValue}
            value={filterValue}
            onChange={(data: any) => {
              setFilterValue(data);
            }}
          >
            {[
              filterData,
              [
                {
                  field: "enabled",
                  value: true,
                  name: "Active",
                  operator: "=",
                },
                {
                  field: "enabled",
                  value: false,
                  name: "Inactive",
                  operator: "=",
                },
              ],
            ]?.map((item, idx) => (
              <>
                <div className="flex-col space-y-2">
                  {item.map((filter, index) => (
                    <Checkbox
                      key={index}
                      value={JSON.stringify(filter)}
                      label={filter.name}
                    />
                  ))}
                </div>
                {[
                  filterData,
                  [
                    {
                      field: "enabled",
                      value: true,
                      name: "Active",
                      operator: "=",
                    },
                    {
                      field: "enabled",
                      value: false,
                      name: "Inactive",
                      operator: "=",
                    },
                  ],
                ].length !==
                  idx + 1 && (
                  <Divider label="And" labelPosition="center" my={"sm"} />
                )}
              </>
            ))}
          </Checkbox.Group> */}
        </div>
        <div className="flex justify-end w-full mt-4">
          <Button
            loading={exportedPayouts?.isLoading}
            onClick={() => onExport()}
            size="xs"
            leftIcon={<IconFileExport />}
            className="flex text-white bg-primary"
          >
            Export
          </Button>
        </div>
      </>
    </Modal>
  );
}
