import { yupResolver } from "@hookform/resolvers/yup";
import {
  Avatar,
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Textarea,
} from "@mantine/core";
import { IconInbox, IconPhone, IconWallet } from "@tabler/icons";
import dateFormat from "dateformat";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import AuthContext from "../../../shared/auth/context/authContext";
import Card from "../../../shared/component/Card/card-component";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { downloadUrlParser } from "../../../shared/utility/Tools/tools";
import {
  useCancelBookingMutation,
  useLazyGetBookingQuery,
} from "../store/booking.query";

export default function BookingDetailComponent() {
  const params = useParams();
  const [open, setOpened] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const [getBooking, booking] = useLazyGetBookingQuery();
  const [cancelBooking, cancelResponse] = useCancelBookingMutation();
  const schema = yup
    .object()
    .shape({
      reason: yup.string().required("Reason  is required"),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { reason: "" },
    mode: "all",
  });

  const onSubmit = (data: any) => {
    const cancelData = {
      id: params.id,
      reason: {
        reason: data.reason,
        cancelledBy: user?.name,
        cancelledAt: new Date(),
      },
    };
    cancelBooking(cancelData).then(() => {});
  };
  const onError = (error: any) => {};

  useEffect(() => {
    getBooking(`${params.id}`);
  }, [params?.id]);
  return (
    <Card
      title={"Booking Detail"}
      action={
        booking?.data?.fee && (
          <div className="flex items-center space-x-2">
            {booking?.data?.status === "Created" && (
              <div className="flex items-center h-full">
                <Confirmation
                  type={"danger"}
                  message={"Are you sure you want to cancel this booking?"}
                  header={`Cancel`}
                  result={"single"}
                  resultRequired={true}
                  selectorLabel={"Please select a reason"}
                  labelDescription={"Why are you canceling this booking?"}
                  options={[
                    `It's not longer needed`,
                    `It is incorrect and can't update it`,
                  ].map((reason) => {
                    return { label: reason, value: reason };
                  })}
                  customInput={true}
                  yesText={"Cancel Booking"}
                  onYes={(reason: string) => {
                    cancelBooking({
                      id: params?.id,
                      reason: {
                        reason: reason,
                        cancelledBy: user?.name,
                        cancelledAt: new Date(),
                      },
                    });
                  }}
                >
                  <Button
                    loading={cancelResponse?.isLoading}
                    variant={"outline"}
                    size="xs"
                    className="w-48"
                  >
                    Cancel Booking
                  </Button>
                </Confirmation>
              </div>
            )}
            <div className="flex-col my-2">
              <div className="flex items-center px-2 space-x-1">
                <span className="text-sm font-semibold dark:text-gray-300">
                  Fee:
                </span>
                <span className="text-xs text-green-500 dark:text-gray-300">
                  {booking?.data?.fee?.toFixed(2)} ETB
                </span>
              </div>
              <div className="flex items-center px-2 space-x-1">
                <span className="text-sm font-semibold dark:text-gray-300">
                  Status:
                </span>
                <span className="text-xs text-green-500 dark:text-gray-300">
                  {booking?.data?.status === "Created"
                    ? "Pending"
                    : booking?.data?.status}
                </span>
              </div>
            </div>
          </div>
        )
      }
      className={"pb-8"}
    >
      {booking?.isLoading && (
        <div className="relative flex items-center justify-center w-full h-full">
          <LoadingOverlay visible={true} />
          <EmptyIcon />
        </div>
      )}
      {booking?.isSuccess && booking.data ? (
        <div className="relative flex-col w-full h-screen space-y-2">
          <LoadingOverlay visible={booking?.isFetching} />
          <div className="flex-col px-2 mb-8 border border-gray-300 rounded-xl">
            <div className="flex w-full py-2 space-x-4 border-0 border-b">
              <div className="">
                <Avatar
                  className="rounded-lg h-28 w-28"
                  src={downloadUrlParser({
                    path: booking?.data?.passenger?.profileImage?.path,
                    filename: booking?.data?.passenger?.profileImage?.filename,
                    originalname:
                      booking?.data?.passenger?.profileImage?.originalname,
                    mimetype: booking?.data?.passenger?.profileImage?.mimetype,
                  })}
                  alt="photo"
                />
              </div>
              <div className="w-full">
                <div className="flex space-x-3 dark:text-white">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 fill-current"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 3 A 4 4 0 0 0 8 7 A 4 4 0 0 0 12 11 A 4 4 0 0 0 16 7 A 4 4 0 0 0 12 3 z M 3 6.5800781L3 9.4199219L8.140625 14.550781L14.589844 21L21 21L21 18.5C21 15.55 15.14 14 12 14C11.55 14 11.049766 14.039609 10.509766 14.099609L3 6.5800781 z M 6.0292969 15.279297C4.3092969 16.019297 3 17.1 3 18.5L3 21L11.75 21L6.0292969 15.279297 z" />
                    </svg>
                  </span>
                  <span className="font-semibold dark:text-white ">
                    Passenger
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="text-sm text-gray-700 dark:text-white">
                    Name:
                  </span>
                  <span className="text-sm text-slate-500">
                    {booking?.data?.passengerName}
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="text-gray-700 dark:text-white">Sex:</span>
                  <span className="text-sm text-slate-500">
                    {booking?.data?.passengerGender}
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="text-gray-700 dark:text-white">
                    <IconPhone size={14} />
                  </span>
                  <span className="text-sm text-slate-500">
                    {booking?.data?.passengerPhoneNumber}
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="text-gray-700 dark:text-white">
                    <IconWallet size={14} />
                  </span>
                  <span className="text-sm text-slate-500">
                    {booking?.data?.walletType}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex justify-end w-full py-2 space-x-4 border-0 border-b">
              <div className="">
                <div className="flex space-x-3 dark:text-white">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 fill-current"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 0C9.789063 0 8 2.015625 8 4.5C8 6.984375 9.789063 9 12 9C14.210938 9 16 6.984375 16 4.5C16 2.015625 14.210938 0 12 0 Z M 12 10C6.699219 10 4.085938 14.5 1.1875 18.5L1.09375 18.8125C0.894531 19.613281 1 20.398438 1.5 21L3.8125 24L6.09375 24C5.992188 23.699219 6 23.300781 6 23C6 20.199219 8.699219 18 12 18C15.300781 18 18 20.199219 18 23C18 23.300781 18.007813 23.699219 17.90625 24L20.1875 24L22.5 21C23 20.398438 23.105469 19.613281 22.90625 18.8125L22.8125 18.5C19.914063 14.5 17.300781 10 12 10 Z M 12 19.59375C9.601563 19.59375 7.59375 21.101563 7.59375 23C7.59375 23.398438 7.710938 23.699219 7.8125 24L16.1875 24C16.289063 23.699219 16.40625 23.398438 16.40625 23C16.40625 21.101563 14.398438 19.59375 12 19.59375Z" />
                    </svg>
                  </span>
                  <span className="font-semibold dark:text-white">
                    Provider
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="text-sm text-gray-700 dark:text-white">
                    Name:
                  </span>
                  <span className="text-sm text-slate-500">
                    {booking?.data?.driver?.name}
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="text-sm text-gray-700 dark:text-white">
                    Sex:
                  </span>
                  <span className="text-sm text-slate-500">
                    {booking?.data?.driver?.gender}
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="text-gray-700 dark:text-white">
                    <IconPhone size={14} />
                  </span>
                  <span className="text-sm text-slate-500">
                    {booking?.data?.driver?.phoneNumber}
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="text-gray-700 dark:text-white">
                    <IconInbox size={14} />
                  </span>
                  <span className="text-sm text-slate-500">
                    {booking?.data?.driver?.email}
                  </span>
                </div>
              </div>
              <div>
                <Avatar
                  className="rounded-lg h-28 w-28"
                  src={downloadUrlParser({
                    path: booking?.data?.driver?.profileImage?.path,
                    filename: booking?.data?.driver?.profileImage?.filename,
                    originalname:
                      booking?.data?.driver?.profileImage?.originalname,
                    mimetype: booking?.data?.driver?.profileImage?.mimetype,
                  })}
                  alt="photo"
                />
              </div>
            </div>
          </div>

          <div className="flex-col w-full p-2 mt-20 space-y-4 border border-gray-300 rounded-lg ">
            <div className="flex justify-start border-0 border-b border-dashed">
              <div className="flex-col space-y-2">
                <div className="flex items-center space-x-1">
                  <span className="text-sm text-gray-700 dark:text-gray-300">
                    Route Name:
                  </span>
                  <span className="text-sm text-slate-500">
                    {booking?.data?.routeName}
                  </span>
                </div>

                <div className="flex items-center space-x-1">
                  <span className="text-sm text-gray-700 dark:text-gray-300">
                    Pickup Name:
                  </span>
                  <span className="text-sm text-slate-500">
                    {booking?.data?.pickupName}
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="text-sm text-gray-700 dark:text-gray-300">
                    Destination Name:
                  </span>
                  <span className="text-sm text-slate-500">
                    {booking?.data?.destinationName}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex justify-end border-0 border-b border-dashed">
              <div className="flex-col">
                <div className="flex items-center space-x-1">
                  <span className="text-sm text-gray-700 dark:text-gray-300">
                    Vehicle:
                  </span>
                  <span className="text-sm text-slate-500">
                    {booking?.data?.driver?.vehicle?.model}
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="text-sm text-gray-700 dark:text-gray-300">
                    Plate Number:
                  </span>
                  <span className="text-sm text-slate-500">
                    {booking?.data?.driver?.vehicle?.plateNumber}
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="text-sm text-gray-700 dark:text-gray-300">
                    Color:
                  </span>
                  <span className="text-sm text-slate-500">
                    {booking?.data?.driver?.vehicle?.color}
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="text-sm text-gray-700 dark:text-gray-300">
                    Code:
                  </span>
                  <span className="text-sm text-slate-500">
                    {booking?.data?.driver?.vehicle?.code}
                  </span>
                </div>
              </div>
            </div>

            {booking?.data?.startingAt && (
              <div className="flex justify-between border-0 border-b border-dashed">
                <div className="flex-col space-y-2">
                  {booking?.data?.startingAt && (
                    <div className="flex items-center space-x-1">
                      <span className="text-sm text-gray-700 dark:text-gray-300">
                        Started At:
                      </span>
                      <span className="text-sm text-slate-500">
                        {dateFormat(booking?.data?.startingAt)}
                      </span>
                    </div>
                  )}
                  {booking?.data?.completedAt && (
                    <div className="flex items-center space-x-1">
                      <span className="text-sm text-gray-700 dark:text-gray-300">
                        Completed At:
                      </span>
                      <span className="text-sm text-slate-500">
                        {dateFormat(booking?.data?.completedAt)}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}

            {booking?.data?.cancelledAt && (
              <div className="flex justify-between border-0 border-b border-dashed">
                <div className="flex-col space-y-2">
                  <div className="flex items-center space-x-1">
                    <span className="text-sm text-gray-700 dark:text-gray-300">
                      Canceled At:
                    </span>
                    <span className="text-sm text-slate-500">
                      {dateFormat(booking?.data?.cancelledAt)}
                    </span>
                  </div>
                  <div className="flex items-center space-x-1">
                    <span className="text-sm text-gray-700 dark:text-gray-300">
                      Canceled By:
                    </span>
                    <span className="text-sm text-slate-500">
                      {booking?.data?.cancellationReason?.cancelledBy}
                    </span>
                  </div>
                  <div className="flex items-center space-x-1">
                    <span className="text-sm text-gray-700 dark:text-gray-300">
                      Reason:
                    </span>
                    <span className="text-sm text-slate-500">
                      {booking?.data?.cancellationReason?.reason}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        !(booking?.isLoading || booking?.isFetching) && (
          <div className="flex items-center justify-center w-full h-full">
            <EmptyIcon />
          </div>
        )
      )}

      <Modal
        opened={open}
        onClose={() => setOpened(false)}
        centered
        classNames={{ root: "border border-primary" }}
      >
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          className="flex justify-center w-full"
        >
          <div className="flex-col w-3/4 space-y-4">
            <Textarea
              label="Your Reason"
              placeholder="your reason"
              error={errors?.reason && `${errors?.reason?.message}`}
              {...register("reason")}
            />

            <div className="flex items-center justify-end w-full">
              <Group position="right" mt="xl">
                <Button
                  variant="filled"
                  className="text-white bg-primary"
                  type="submit"
                  classNames={{ label: "flex space-x-1" }}
                  size={"xs"}
                  loading={cancelResponse?.isLoading}
                >
                  <span>Done</span>
                </Button>
              </Group>
            </div>
          </div>
        </form>
      </Modal>
    </Card>
  );
}
