import {
  Box,
  Collapse,
  Group,
  Menu,
  ThemeIcon,
  UnstyledButton,
  createStyles,
  rem,
} from "@mantine/core";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

interface LinksGroupProps {
  icon: React.FC<any>;
  label: string;
  initiallyOpened?: boolean;
  active?: boolean;
  links?: {
    label: string;
    link?: string;
    Icon?: React.FC<any>;
    active?: boolean;
    secondlinks?: {
      label: string;
      link: string;
      Icon?: React.FC<any>;
      active?: boolean;
    }[];
  }[];
  collapse: boolean;
  link?: string;
}

export function LinkDropDown({
  icon: Icon,
  label,
  initiallyOpened,
  links,
  collapse,
  link,
  active,
}: LinksGroupProps) {
  const useStyles = createStyles((theme) => ({
    control: {
      fontWeight: 500,
      display: "block",
      width: "100%",
      padding: `${theme.spacing.xs} ${theme.spacing.md}`,
      color: theme.colors.dark[0],
      fontSize: theme.fontSizes.sm,

      "&:hover": {
        backgroundColor: theme.colors.dark[7],
        color: theme.white,
      },
    },

    link: {
      fontWeight: 500,
      display: "block",
      textDecoration: "none",
      cursor: "pointer",
      padding: `${theme.spacing.xs} ${theme.spacing.md}`,
      paddingLeft: collapse ? rem(8) : rem(31),
      marginLeft: collapse ? rem(8) : rem(30),
      fontSize: theme.fontSizes.sm,
      color: theme.colors.dark[0],
      borderLeft: `${rem(1)} solid ${theme.colors.dark[4]}`,

      "&:hover": {
        backgroundColor: theme.colors.dark[7],
        color: theme.white,
      },
    },

    chevron: {
      transition: "transform 200ms ease",
    },
  }));

  const { classes, theme } = useStyles();
  const navigate = useNavigate();
  const hasLinks = Array.isArray(links);

  const [opened, setOpened] = useState(initiallyOpened || false);
  const [openedLinks, setOpenedLinks] = useState<{ [key: string]: boolean }>(
    {}
  );
  const ChevronIcon = theme.dir === "ltr" ? IconChevronRight : IconChevronLeft;

  const toggleSecondLinks = (label: string) => {
    setOpenedLinks((prev) => ({
      ...prev,
      [label]: !prev[label],
    }));
  };

  const items = (hasLinks ? links : []).map((link) => {
    const hasSecondLinks = link.secondlinks && link.secondlinks.length > 0;
    const secondLinksOpened = openedLinks[link.label] || false;

    return (
      <div key={link.label}>
        {hasSecondLinks ? (
          <div
            className={`${classes.link} ${
              link?.active ? "bg-primary text-white" : ""
            }`}
            // to={hasSecondLinks ? "" : link?.link || ""}
            onClick={() => toggleSecondLinks(link.label)}
          >
            <div className="flex items-center justify-between">
              <span>
                {collapse ? (
                  link.Icon ? (
                    <div className="flex space-x-2 text-sm">
                      <div> {link.label}</div>
                    </div>
                  ) : (
                    link.label
                  )
                ) : (
                  link.label
                )}
              </span>
              <ChevronIcon
                className={classes.chevron}
                size="1rem"
                stroke={1.5}
                style={{
                  transform: secondLinksOpened ? `rotate(90deg)` : "none",
                }}
              />
            </div>
          </div>
        ) : (
          <NavLink
            className={`${classes.link} ${
              link?.active ? "bg-primary text-white" : ""
            }`}
            to={link?.link || ""}
          >
            <div className="flex items-center justify-between">
              <span>
                {collapse ? (
                  link.Icon ? (
                    <div className="flex space-x-2 text-sm">
                      <div> {link.label}</div>
                    </div>
                  ) : (
                    link.label
                  )
                ) : (
                  link.label
                )}
              </span>
            </div>
          </NavLink>
        )}
        {hasSecondLinks && secondLinksOpened && (
          <div className="ml-4">
            {link?.secondlinks?.map((secondLink) => (
              <NavLink
                className={`${classes.link} ${
                  secondLink?.active ? "bg-primary text-white" : ""
                }`}
                to={secondLink.link}
                key={secondLink.label}
              >
                {secondLink.label}
              </NavLink>
            ))}
          </div>
        )}
      </div>
    );
  });

  return (
    <>
      {!collapse && (
        <UnstyledButton
          onClick={() => {
            setOpened((o) => !o);
            if (link) {
              navigate(link);
            }
          }}
          className={`${classes.control} ${
            active ? "bg-primary text-white" : ""
          }`}
        >
          <div className="flex items-center justify-between">
            {link ? (
              <NavLink to={link}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ThemeIcon variant="dark" className="text-gray-400" size={30}>
                    <Icon size="1.1rem" />
                  </ThemeIcon>
                  {!collapse && <Box ml="md">{label}</Box>}
                </Box>
              </NavLink>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ThemeIcon variant="dark" className="text-gray-400" size={30}>
                  <Icon size="1.1rem" />
                </ThemeIcon>
                {!collapse && <Box ml="md">{label}</Box>}
              </Box>
            )}
            {hasLinks && !collapse && (
              <ChevronIcon
                className={classes.chevron}
                size="1rem"
                stroke={1.5}
                style={{
                  transform: opened
                    ? `rotate(${theme.dir === "rtl" ? -90 : 90}deg)`
                    : "none",
                }}
              />
            )}
          </div>
        </UnstyledButton>
      )}
      {hasLinks ? (
        !collapse ? (
          <Collapse in={opened}>{items}</Collapse>
        ) : (
          <Menu
            trigger="hover"
            position="right-start"
            transitionProps={{ duration: 150 }}
          >
            <Menu.Target>
              <UnstyledButton
                onClick={() => setOpened((o) => !o)}
                className={classes.control}
              >
                <Group position="apart" spacing={0}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ThemeIcon variant="light" size={30}>
                      <Icon size="1.1rem" />
                    </ThemeIcon>
                    {!collapse && <Box ml="md">{label}</Box>}
                  </Box>
                  {hasLinks && !collapse && (
                    <ChevronIcon
                      className={classes.chevron}
                      size="1rem"
                      stroke={1.5}
                      style={{
                        transform: opened
                          ? `rotate(${theme.dir === "rtl" ? -90 : 90}deg)`
                          : "none",
                      }}
                    />
                  )}
                </Group>
              </UnstyledButton>
            </Menu.Target>

            <Menu.Dropdown className="text-white bg-gray-900 hover:bg-gray-900">
              {items?.map((item) => (
                <Menu.Item className="text-xs bg-gray-900" key={item.key}>
                  {item}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        )
      ) : (
        collapse && (
          <NavLink
            to={!hasLinks && link ? link : ""}
            className={`${classes.control} ${
              active ? "bg-primary text-white" : ""
            }`}
          >
            <Group position="apart" spacing={0}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ThemeIcon variant="light" size={30}>
                  <Icon size="1.1rem" />
                </ThemeIcon>
              </Box>
            </Group>
          </NavLink>
        )
      )}
    </>
  );
}
