import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Group, Switch, TextInput } from "@mantine/core";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Configuration } from "../../../models/configuration.model";
import Card from "../../../shared/component/Card/card-component";
import {
  useLazyGetConfigurationQuery,
  useUpdateConfigurationMutation,
} from "../store/configuration.query";

export default function ConfigurationPage() {
  const navigate = useNavigate();

  const schema = yup
    .object()
    .shape({
      groupingRadius: yup
        .number()
        .typeError("Grouping Radius should be a number")
        .required("Grouping Radius is required"),
      timeout: yup
        .number()
        .typeError("Timeout should be a number")
        .required("Timeout is required"),
      recentParentIosVersion: yup
        .string()
        .required("Recent parents IOS version is required"),
      recentPassengerIosVersion: yup
        .string()
        .required("Recent passengers IOS version is required"),
      recentDriverIosVersion: yup
        .string()
        .required("Recent drivers IOS version is required"),
      isBeingMaintained: yup
        .boolean()
        .typeError("Maintenance Mode should be a boolean")
        .required("Maintenance Mode is required"),
      telebirrStatus: yup
        .boolean()
        .typeError("Telebirr status should be a boolean")
        .required("Telebirr status is required"),
      chapaStatus: yup
        .boolean()
        .typeError("Chapa status should be a boolean")
        .required("Chapa status is required"),
      kidsNumberOfSchoolDays: yup
        .number()
        .typeError("Kids Number of School Days should be a number")
        .required("Kids Number of School Days is required"),
      passengerNumberOfDays: yup
        .number()
        .typeError("Number of passenger working days should be a number")
        .required("Number of passenger working days is required"),
      passengerRegistrationFee: yup
        .number()
        .typeError("Passenger registration fee should be a number")
        .required("Passenger registration fee is required"),
      minimumDistance: yup
        .number()
        .typeError("Minimum distance should be a number")
        .required("Minimum distance is required"),
      onDemandInitialFee: yup
        .number()
        .typeError("On Demand Initial Fee should be a number")
        .required("On Demand Initial Fee is required"),
      onDemandPerKiloMeterCost: yup
        .number()
        .typeError("On Demand Per Kilometer cost should be a number")
        .required("On Demand Per Kilometer cost is required"),
      daysAfterExpiry: yup
        .number()
        .typeError("Days After Expiry should be a number")
        .required("Days After Expiry is required"),
    })
    .required();

  const defaultValue: Configuration = {
    groupingRadius: 5,
    timeout: 0,
    isBeingMaintained: false,
    telebirrStatus: true,
    chapaStatus: true,
    isParentLive: false,
    isPassengerLive: false,
    isDriverLive: false,
    kidsNumberOfSchoolDays: 23,
    passengerNumberOfDays: 6,
    daysAfterExpiry: 5,
    onDemandInitialFee: 220,
    onDemandPerKiloMeterCost: 16,
    kidsDriverCommission: 70,
    minimumDistance: 6,
  };
  const [getConfiguration, configuration] = useLazyGetConfigurationQuery();
  const [updateConfiguration, updateConfigurationResponse] =
    useUpdateConfigurationMutation();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValue,
    mode: "all",
  });
  const watchedTelebirrStatus = watch("telebirrStatus");
  const watchedChapaStatus = watch("chapaStatus");
  const watchedManualPaymentStatus = watch("manualPaymentStatus");
  const watchedIsParentLive = watch("isParentLive");
  const watchedIsDriverLive = watch("isDriverLive");
  const watchedIsPassengerLive = watch("isPassengerLive");
  const watchedIsBeingMaintained = watch("isBeingMaintained");
  const onSubmit = (data: any) => {
    data.kidsDriverCommission = new Number(data.kidsDriverCommission);
    data.minimumDistance = new Number(data.minimumDistance);
    updateConfiguration({
      id: configuration?.data?.id ?? "",
      ...data,
    })
      .unwrap()
      .then((response) => {
        reset(response);
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    getConfiguration("")
      .unwrap()
      .then((response: Configuration) => {
        if (response) {
          reset(response);
        }

        // setValue("daysAfterExpiry", response?.daysAfterExpiry);
        // setValue("groupingRadius", response?.groupingRadius);
        // setValue("isBeingMaintained", response?.isBeingMaintained);
        // setValue("kidsNumberOfSchoolDays", response?.kidsNumberOfSchoolDays);
        // setValue("passengerNumberOfDays", response?.passengerNumberOfDays);
        // setValue("timeout", response?.timeout);
        // setValue("kidsDriverCommission", response?.kidsDriverCommission);
        // setValue("minimumDistance", response?.minimumDistance);
        // setValue("onDemandInitialFee", response?.onDemandInitialFee);
        // setValue(
        //   "onDemandPerKiloMeterCost",
        //   response?.onDemandPerKiloMeterCost
        // );
        // setValue("recentDriverIosVersion", response?.recentDriverIosVersion);
        // setValue("recentParentIosVersion", response?.recentParentIosVersion);
        // setValue(
        //   "recentPassengerIosVersion",
        //   response?.recentPassengerIosVersion
        // );
        // setValue("chapaStatus", response?.chapaStatus);
        // setValue("telebirrStatus", response?.telebirrStatus);
        // setValue("manualPaymentStatus", response?.manualPaymentStatus);
        // setValue("isDriverLive", response?.isDriverLive);
        // setValue("isParentLive", response?.isParentLive);
        // setValue("isPassengerLive", response?.isPassengerLive);
        // setValue(
        //   "passengerRegistrationFee",
        //   response?.passengerRegistrationFee
        // );
      })
      .catch((e: Error) => {});
  }, [getConfiguration]);
  const onError = (error: any) => {};

  const config: Configuration | undefined = configuration?.data;

  return (
    <div className="h-full p-4">
      <Card title={"Configuration"}>
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          className="w-full p-2 m-2 gap-y-4 dark:text-white"
        >
          <div className="flex-col space-y-4">
            <div className="flex justify-start w-full space-x-4">
              <TextInput
                placeholder="Radius"
                className="w-full"
                label="Radius"
                error={
                  errors?.groupingRadius && `${errors?.groupingRadius?.message}`
                }
                {...register("groupingRadius")}
                withAsterisk
              />
              <TextInput
                placeholder="Timeout"
                className="w-full"
                label="Timeout"
                error={errors?.timeout && `${errors?.timeout?.message}`}
                {...register("timeout")}
                withAsterisk
              />
            </div>
            <div className="flex justify-start w-full space-x-4">
              <div className="flex w-full space-x-3">
                {" "}
                <TextInput
                  placeholder="Kids Number of school days"
                  label="Kids Number of school days"
                  className="w-full"
                  error={
                    errors?.kidsNumberOfSchoolDays &&
                    `${errors?.kidsNumberOfSchoolDays?.message}`
                  }
                  {...register("kidsNumberOfSchoolDays")}
                  withAsterisk
                />
              </div>

              <TextInput
                placeholder="Days after expiry"
                label="Days after expiry"
                className="w-full"
                error={
                  errors?.daysAfterExpiry &&
                  `${errors?.daysAfterExpiry?.message}`
                }
                {...register("daysAfterExpiry")}
                withAsterisk
              />
            </div>
            <div className="flex justify-start w-full space-x-4">
              <TextInput
                placeholder="Number of passenger working days"
                label="Number of Passenger Working Days"
                className="w-full"
                error={
                  errors?.passengerNumberOfDays &&
                  `${errors?.passengerNumberOfDays?.message}`
                }
                {...register("passengerNumberOfDays")}
                withAsterisk
              />

              <TextInput
                placeholder="Passenger registration fee"
                label="Passenger Registration Fee"
                className="w-full"
                error={
                  errors?.passengerRegistrationFee &&
                  `${errors?.passengerRegistrationFee?.message}`
                }
                {...register("passengerRegistrationFee")}
                withAsterisk
              />
            </div>
            <div className="flex justify-start w-full space-x-4">
              <TextInput
                placeholder="On Demand per kilometer cost"
                label="On Demand per kilometer cost"
                className="w-full"
                error={
                  errors?.onDemandPerKiloMeterCost &&
                  `${errors?.onDemandPerKiloMeterCost?.message}`
                }
                {...register("onDemandPerKiloMeterCost")}
                withAsterisk
              />
              <TextInput
                placeholder="On Demand Initial Fee"
                label="On Demand Initial Fee"
                className="w-full"
                error={
                  errors?.onDemandInitialFee &&
                  `${errors?.onDemandInitialFee?.message}`
                }
                {...register("onDemandInitialFee")}
                withAsterisk
              />
            </div>
            <div className="flex justify-start w-full space-x-4">
              <TextInput
                placeholder="Kids Driver Commision"
                label="Kids Driver Commision"
                className="w-full"
                type="number"
                error={
                  errors?.kidsDriverCommission &&
                  `${errors?.kidsDriverCommission?.message}`
                }
                {...register("kidsDriverCommission")}
                withAsterisk
              />
              <TextInput
                placeholder="Minimum Distance"
                label="Minimum Distance"
                className="w-full"
                type="number"
                error={
                  errors?.minimumDistance &&
                  `${errors?.minimumDistance?.message}`
                }
                {...register("minimumDistance")}
                withAsterisk
              />
            </div>
            <div className="flex justify-start w-full space-x-4">
              <div className="flex w-full space-x-2">
                {" "}
                <TextInput
                  placeholder="1.0.0"
                  label="Recent Passengers IOS Version"
                  className="w-full"
                  error={
                    errors?.recentPassengerIosVersion &&
                    `${errors?.recentPassengerIosVersion?.message}`
                  }
                  {...register("recentPassengerIosVersion")}
                  withAsterisk
                />
                <Switch
                  label="Is Passenger App Live?"
                  className="w-full mt-6"
                  checked={watchedIsPassengerLive}
                  {...register("isPassengerLive")}
                  error={
                    errors?.isPassengerLive &&
                    `${errors?.isPassengerLive?.message}`
                  }
                />
              </div>
              <div className="flex w-full space-x-2">
                {" "}
                <TextInput
                  placeholder="1.0.0"
                  label="Recent Drivers IOS Version"
                  className="w-full"
                  error={
                    errors?.recentDriverIosVersion &&
                    `${errors?.recentDriverIosVersion?.message}`
                  }
                  {...register("recentDriverIosVersion")}
                  withAsterisk
                />
                <Switch
                  label="Is Driver App Live?"
                  className="w-full mt-6"
                  checked={watchedIsDriverLive}
                  {...register("isDriverLive")}
                  error={
                    errors?.isDriverLive && `${errors?.isDriverLive?.message}`
                  }
                />
              </div>
            </div>

            <div className="flex items-center justify-start w-full space-x-5">
              <div className="flex w-full space-x-2">
                {" "}
                <TextInput
                  placeholder="1.0.0"
                  label="Recent Parents IOS Version"
                  className="w-full"
                  error={
                    errors?.recentParentIosVersion &&
                    `${errors?.recentParentIosVersion?.message}`
                  }
                  {...register("recentParentIosVersion")}
                  withAsterisk
                />
                <Switch
                  label="Is Parent App Live?"
                  className="w-full mt-6"
                  checked={watchedIsParentLive}
                  {...register("isParentLive")}
                  error={
                    errors?.isParentLive && `${errors?.isParentLive?.message}`
                  }
                />
              </div>
              <div className="flex justify-between w-full pt-2">
                {" "}
                <Switch
                  label="Telebirr Status"
                  className="w-full"
                  checked={watchedTelebirrStatus}
                  {...register("telebirrStatus")}
                  error={
                    errors?.telebirrStatus &&
                    `${errors?.telebirrStatus?.message}`
                  }
                />
                <Switch
                  label="Chapa Status"
                  className="w-full "
                  checked={watchedChapaStatus}
                  {...register("chapaStatus")}
                  error={
                    errors?.chapaStatus && `${errors?.chapaStatus?.message}`
                  }
                />
                <Switch
                  label="Manual Payment Status"
                  className="w-full "
                  checked={watchedManualPaymentStatus}
                  {...register("manualPaymentStatus")}
                  error={
                    errors?.manualPaymentStatus &&
                    `${errors?.manualPaymentStatus?.message}`
                  }
                />
                <Switch
                  label="Maintenance Mode"
                  className="w-full "
                  checked={watchedIsBeingMaintained}
                  {...register("isBeingMaintained")}
                  error={
                    errors?.isBeingMaintained &&
                    `${errors?.isBeingMaintained?.message}`
                  }
                />
              </div>
            </div>

            <div className="flex items-center justify-end w-full">
              <Group position="center" mt="xl">
                <Button
                  variant="filled"
                  className="w-40 text-white bg-primary"
                  type="submit"
                  classNames={{ label: "flex space-x-1" }}
                  size={"xs"}
                  loading={updateConfigurationResponse?.isLoading}
                >
                  <span>Update</span>
                </Button>
              </Group>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}
