import { Outlet, RouteObject } from "react-router-dom";
import AuthGuard from "../../../shared/auth/component/auth";
import TripPage from "../../trip/page/trip-list-page";
import AssignmentBookingsComponent from "../component/bookings-component";
import RouteAssignmentFormComponent from "../component/route-assignment.form.component";
import RouteAssignmentPage from "../page/route-assignment-page";
import AssignmentPaymentHistoryComponent from "../sub-component/payment-history";
import AssignmentPaymentHistoryDetailComponent from "../sub-component/payment-history-detail";
export const RouteAssignmentRoute: RouteObject = {
  path: "/assignment",
  element: (
    <AuthGuard role={["super_admin", "admin", "finance", "operator"]}>
      <Outlet />
    </AuthGuard>
  ),
  children: [
    {
      path: "detail/:id",
      element: <RouteAssignmentFormComponent editMode="detail" />,
    },
    // { path: "bulk", element: <BulkRouteAssignmentFormComponent /> },
    // { path: "bulk", element: <RouteAssignmentFormComponent editMode="new" /> },
    { path: "new", element: <RouteAssignmentFormComponent editMode="new" /> },
    { path: "", element: <RouteAssignmentPage /> },
    { path: "bookings/:id", element: <AssignmentBookingsComponent /> },
    { path: "trips/:assignmentId", element: <TripPage /> },
  ],
};
export const driverPaymentsRoute: RouteObject = {
  path: "/driver-payments",
  element: (
    <AuthGuard role={["super_admin", "admin", "finance"]}>
      <AssignmentPaymentHistoryComponent />
    </AuthGuard>
  ),
  children: [
    {
      path: "detail/:type/:id",
      element: (
        <AuthGuard role={["super_admin", "admin", "finance"]}>
          <AssignmentPaymentHistoryDetailComponent />
        </AuthGuard>
      ),
    },
  ],
};
