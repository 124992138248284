import { Button, Group, LoadingOverlay, Radio, Text } from "@mantine/core";
import {
  IconArrowDownRight,
  IconArrowUpRight,
  IconFileExport,
} from "@tabler/icons";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { lazy, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { NumericFormat } from "react-number-format";
import { CollectionQuery } from "../../../models/collection.model";
import { dateFormat } from "../../../shared/utility/date-format/date-format";
import { NewBookingStatus } from "../../../shared/utility/enum/other.enum";
import { StatsRing } from "../component/status-ring";
import {
  useLazyGetBookingValueQuery,
  useLazyGetBookingsDashboardQuery,
  useLazyGroupBookingsByCreatedMonthQuery,
  useLazyGroupBookingsByStatusQuery,
} from "../store/dashboard.query";
const BookingsExportModal = lazy(
  () => import("../component/bookings-export-modal-component")
);
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

interface StatsRingProps {
  data: {
    label: string;
    stats: string;
    progress: number;
    color: string;
    icon: "up" | "down";
  }[];
}
const icons = {
  up: IconArrowUpRight,
  down: IconArrowDownRight,
};

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Vehicles Status",
    },
  },
};

export const bar_options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];
const active = [100, 500, 550, 635, 500, 600, 900];
const deactive = [10, 3, 200, 100, 4, 14, 62];

export const data = {
  labels,
  datasets: [
    {
      label: "Active",
      data: labels.map((_, index) => active[index]),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
    {
      label: "Inactive",
      data: labels.map((_, deactiveIndex) => deactive[deactiveIndex]),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ],
};

const colorPicker = (code: string) => {
  switch (code) {
    case "Created":
      return "blue";
    case "Booked":
      return "yellow";
    case "Expired":
      return "red";
    case "Cancelled":
      return "red";
    case "Missed":
      return "black";
    default:
      return "pink";
  }
};

export default function BookingDashboardPage() {
  const [openExport, setOpenExport] = useState<boolean>(false);
  const [createdDateFormat, setCreatedDateFormat] = useState("month");
  const [donut_state_data, setDonutData] = useState<any>();
  const [barFilter, setBarFilter] = useState<any>("Created");
  const [bar_state_data, setBarData] = useState<any>();
  const [passengersCircleProgress, setOwnersCircleProgress] = useState<any[]>(
    []
  );
  const [collection, setCollection] = useState<CollectionQuery>({
    filter: [[{ field: "status", value: barFilter, operator: "=" }]],
  });

  const [groupBookingByCreatedMonth, bookingOnCreatedMonth] =
    useLazyGroupBookingsByCreatedMonthQuery();
  const [getBookings, bookings] = useLazyGetBookingsDashboardQuery();
  const [groupBookingsByStatus, statusGroupedBookings] =
    useLazyGroupBookingsByStatusQuery();
  const [getBookingValue, bookingValue] = useLazyGetBookingValueQuery();

  useEffect(() => {
    getBookingValue({ skip: 0 });
    getBookings({ groupBy: ["status"], count: true });
    getBookings({
      skip: 0,
      top: 6,
      orderBy: [{ field: "createdAt", direction: "desc" }],
    }).then((passengerResponse) => {
      groupBookingsByStatus({
        groupBy: ["status"],
      }).then((response) => {
        if (response?.data && passengerResponse?.data) {
          let total = passengerResponse?.data?.count ?? 1;

          const data = response.data.map((item: any) => {
            return {
              label: item.createdAt === "Created" ? "Pending" : item.createdAt,
              stats: item.count,
              progress: (item.count / total) * 100,
              color: colorPicker(item.createdAt),
              icon:
                item.createdAt === "Cancelled" || item.createdAt === "Missed"
                  ? "down"
                  : "up",
            };
          });
          setOwnersCircleProgress(data);
        }
      });
    });
  }, []);

  useEffect(() => {
    let labels: string[];
    let data: number[];
    async function groupBookingsByStatusAndCReatedMonth() {
      const response = await groupBookingByCreatedMonth({
        format: createdDateFormat,
        collectionQ: {
          ...collection,
          filter: [[{ field: "status", value: barFilter, operator: "=" }]],
        },
      });
      if (response?.data) {
        // labels = response?.data?.map((item: any) =>
        //   dateFormat(item.createdAt, {
        //     hour: false,
        //     hour12: false,
        //     weekday: false,
        //     minute: false,
        //     day: false,
        //   })
        // );
        labels = response.data.map((item: any) =>
          createdDateFormat === "month"
            ? dateFormat(item.createdAt, {
                hour: false,
                hour12: false,
                weekday: false,
                minute: false,
                day: false,
              })
            : createdDateFormat === "year"
            ? item.createdAt
            : item.createdAt
        );
        data = response?.data?.map((item: any) => item.count);
      }
      const bar_data = {
        labels,
        datasets: [
          {
            label: "Bookings",
            data: data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 159, 64, 0.2)",
              "rgba(255, 205, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(201, 203, 207, 0.2)",
            ],
            borderColor: [
              "rgb(255, 99, 132)",
              "rgb(255, 159, 64)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(54, 162, 235)",
              "rgb(153, 102, 255)",
              "rgb(201, 203, 207)",
            ],
            borderWidth: 1,
          },
        ],
      };

      setBarData(bar_data);
    }
    groupBookingsByStatusAndCReatedMonth();
  }, [barFilter, createdDateFormat]);

  return (
    <div className="w-full p-4">
      <div className="flex items-center justify-between w-full h-16 px-2 mt-2 mb-2 border border-sky-300">
        <div className="flex-col space-y-2">
          <div className="font-semibold capitalize text-primary">
            Booking dashboard report
          </div>
          <div className="flex space-x-2 font-semibold text-primary">
            <span>
              {"Total: "}
              {bookings?.data?.count ?? 0} {" Bookings and "}
            </span>

            <NumericFormat
              disabled
              suffix={` ETB Booking Value`}
              className="max-w-fit"
              value={bookingValue?.data?.total ?? 0}
              thousandSeparator=","
              decimalScale={2}
            />
          </div>
        </div>

        <div className="flex space-x-4">
          <Button
            leftIcon={<IconFileExport color="gray" />}
            size="sm"
            variant="default"
            onClick={() => setOpenExport(true)}
          >
            Export
          </Button>
        </div>
      </div>
      <div className="flex justify-between space-x-2">
        <div className="w-full p-2 border rounded shadow shadow-sky-100">
          <div className="flex justify-between px-2 mb-2">
            <Text className="text-sm text-gray-600 text-semibold">
              Booking status
            </Text>
            <Text className="flex-col text-sm text-primary text-semibold">
              <div>
                {"Total: "}
                {bookings?.data?.count}
              </div>
            </Text>
          </div>
          {statusGroupedBookings?.data && (
            <StatsRing data={passengersCircleProgress} />
          )}
        </div>
        {/* <div className="flex items-center justify-center w-full p-2 border rounded shadow h-80 shadow-sky-100">
          {companyEarnings?.isLoading ? (
            <LoadingOverlay visible />
          ) : (
            companyEarnings?.data && (
              <div className="flex-col items-center w-full space-y-2">
                <div className="flex justify-center w-full text-2xl font-semibold">
                  Total Company Earning
                </div>
                <div className="flex justify-center w-full text-4xl font-bold">{`${companyEarnings?.data?.total?.toFixed(
                  2
                )} ETB`}</div>
              </div>
            )
          )}
        </div> */}
      </div>
      <div className="flex-col justify-between p-2 mt-10 space-y-4 border rounded shadow">
        <div className="flex space-x-12 ">
          {" "}
          <Radio.Group
            description="Booking Status"
            defaultValue={barFilter}
            onChange={(e) => {
              setBarFilter(e);
            }}
          >
            <Group mt="xs">
              <Radio value={NewBookingStatus.CREATED} label="Pending" />
              <Radio value={NewBookingStatus.BOOKED} label="Booked" />
              <Radio value={NewBookingStatus.CANCELLED} label="Cancelled" />
              <Radio value={NewBookingStatus.EXPIRED} label="Expired" />
            </Group>
          </Radio.Group>
          <Radio.Group
            description="Date Format"
            defaultValue={createdDateFormat}
            onChange={(e) => {
              setCreatedDateFormat(e);
            }}
          >
            <Group mt="xs">
              <Radio value="month" label="Monthly" />
              <Radio value="year" label="Yearly" />
            </Group>
          </Radio.Group>
        </div>

        <div className="flex justify-center w-full p-2 border rounded shadow-lg h-92 shadow-sky-100">
          {bookingOnCreatedMonth?.isLoading ? (
            <LoadingOverlay visible />
          ) : (
            bar_state_data && (
              <Bar options={bar_options} data={bar_state_data} />
            )
          )}
        </div>
      </div>
      {openExport && (
        <BookingsExportModal
          open={openExport}
          onClose={() => setOpenExport(false)}
        />
      )}
    </div>
  );
}
