import { Group, LoadingOverlay, Radio, Text } from "@mantine/core";
import { IconFileExport, IconSchool, IconUser } from "@tabler/icons";
import { lazy, useEffect, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { decodeMonth } from "../../../shared/constants/Constants";
import StudentIcon from "../../../shared/icons/student-icon";
import {
  useLazyGetKidsQuery,
  useLazyGetParentsQuery,
} from "../../parent/store/parent.query";
import { useLazyGetSchoolsQuery } from "../../school/store/school.query";
import { ReportCard } from "../component/ReportCard";
import { StatsRing } from "../component/status-ring";
import {
  useLazyGetBookingsDashboardQuery,
  useLazyGroupBookingsByStatusQuery,
  useLazyGroupKidsByCreatedDateQuery,
  useLazyGroupKidsByGenderQuery,
  useLazyGroupKidsByStatusQuery,
  useLazyGroupKidsByTransportationTimeQuery,
  useLazyGroupParentsByEnabledQuery,
  useLazyGroupParentsByStatusQuery,
} from "../store/dashboard.query";

const ParentsExportModal = lazy(
  () => import("../component/parents-export-modal-component")
);
const KidsExportModal = lazy(
  () => import("../component/kids-export-modal-component")
);

export const donut_options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    labels: {
      position: "right" as const,
    },
    title: {
      display: true,
      text: "Kids Status by Gender",
    },
  },
};

export const bar_options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Registered Kids",
    },
  },
};

export default function KabbaKidsDashboardPage() {
  const [createdDateFormat, setCreatedDateFormat] = useState("month");
  const [openExport, setOpenExport] = useState<boolean>(false);
  const [kidsExport, setOpenKidsExport] = useState<boolean>(false);
  const [donut_state_data, setDonutData] = useState<any>();
  const [bar_state_data, setBarData] = useState<any>();
  const [parentsCircleProgress, setParentsCircleProgress] = useState<any[]>([]);
  const [kidsCircleProgress, setKidsCircleProgress] = useState<any[]>([]);
  const [kidsByTransportationTime, setKidsStatusByTransportationTime] =
    useState<any[]>([]);

  const [getSchool, schools] = useLazyGetSchoolsQuery();
  const [getKids, kids] = useLazyGetKidsQuery();
  const [getParents, parents] = useLazyGetParentsQuery();
  const [getBookings, bookings] = useLazyGetBookingsDashboardQuery();
  const [groupBookingsByStatus, statusGroupedBookings] =
    useLazyGroupBookingsByStatusQuery();
  const [groupParentsByStatus, parentsStatus] =
    useLazyGroupParentsByStatusQuery();
  const [groupKidsByStatus, kidsStatus] = useLazyGroupKidsByStatusQuery();
  const [groupKidsByTransportationTime, kidsStatusByTransportationTime] =
    useLazyGroupKidsByTransportationTimeQuery();
  const [groupParentsByEnabled, groupedParents] =
    useLazyGroupParentsByEnabledQuery();
  const [groupKidsByGender, genderGroupedKids] =
    useLazyGroupKidsByGenderQuery();
  const [GroupKidsByCreatedDate, createdDateResponse] =
    useLazyGroupKidsByCreatedDateQuery();

  const colorPicker = (code: string) => {
    switch (code) {
      case "Created":
        return "blue";
      case "Boarded":
        return "yellow";
      case "Arrived":
        return "green";
      case "Cancelled":
        return "red";
      case "Missed":
        return "black";
      default:
        return "pink";
    }
  };

  useEffect(() => {
    getSchool({ count: true });
    getKids({ count: true });
    getParents({ count: true });
  }, []);

  useEffect(() => {
    groupKidsByStatus({
      groupBy: ["status"],
    }).then((response) => {
      if (response?.data) {
        let total = parents?.data?.count ?? 1;

        const data = response.data.map((item: any) => {
          return {
            label: item.status,
            stats: parseInt(item.count),
            progress: (parseInt(item.count) / total) * 100,
            color: item.status === "Active" ? "green" : "red",
            icon: "up",
          };
        });
        setKidsCircleProgress(data);
      }
    });

    groupKidsByTransportationTime({
      groupBy: ["status"],
    }).then((response) => {
      if (response?.data) {
        let total = parents?.data?.count ?? 1;

        const data = response.data.map((item: any) => {
          return {
            label: item.TransportationTime,
            stats: parseInt(item.count),
            progress: (parseInt(item.count) / total) * 100,
            color: item.TransportationTime === "Active" ? "green" : "red",
            icon: item?.TransportationTime === "Active" ? "up" : "down",
          };
        });
        setKidsStatusByTransportationTime(data);
      }
    });
    groupParentsByEnabled({ groupBy: ["enabled"] }).then((response) => {
      if (response?.data) {
        let total = parents?.data?.count ?? 1;

        const data = response.data.map((item: any) => {
          return {
            label: item.status === "Active" ? "Active" : "Inactive",
            stats: parseInt(item.count),
            progress: (parseInt(item.count) / total) * 100,
            color: item.status === "Active" ? "green" : "red",
            icon: "up",
          };
        });
        setParentsCircleProgress(data);
      }
    });
  }, []);

  useEffect(() => {
    let labels: any[] = [];
    let data: any[] = [];

    async function getKidsByGenderStatus() {
      const response = await groupKidsByGender({ groupBy: ["gender"] });
      if (response.data) {
        labels = response.data.map((item: any) => {
          return item.gender;
        });
        data = response.data.map((item: any) => {
          return item.count;
        });
      }

      const donut_data = {
        labels: labels,
        datasets: [
          {
            label: "Kids",
            data: data ?? [0],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      };
      setDonutData(donut_data);
    }
    getKidsByGenderStatus();
  }, []);

  useEffect(() => {
    let labels: string[];
    let data: number[];
    async function getNewKidsEveryDate() {
      const response = await GroupKidsByCreatedDate(createdDateFormat);
      if (response?.data) {
        labels = response.data.map((item: any) =>
          createdDateFormat === "month"
            ? decodeMonth(item.createdAt)
            : createdDateFormat === "year"
            ? item.createdAt
            : item.createdAt
        );
        data = response.data.map((item: any) => item.count);
      }
      const bar_data = {
        labels,
        datasets: [
          {
            label: "Kids",
            data: data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 159, 64, 0.2)",
              "rgba(255, 205, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(201, 203, 207, 0.2)",
            ],
            borderColor: [
              "rgb(255, 99, 132)",
              "rgb(255, 159, 64)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(54, 162, 235)",
              "rgb(153, 102, 255)",
              "rgb(201, 203, 207)",
            ],
            borderWidth: 1,
          },
        ],
      };

      setBarData(bar_data);
    }
    getNewKidsEveryDate();
  }, [createdDateFormat]);
  return (
    <div className="flex-col p-4 space-y-8">
      <div className="flex p-4 mt-4 space-x-4 border shadow justify-evenly">
        <ReportCard
          {...{
            title: "Schools",
            description: "Number of Schools",

            count: Intl.NumberFormat().format(schools?.data?.count ?? 0),
            loading: schools.isFetching,
            icon: <IconSchool size={100} />,
          }}
        />
        <ReportCard
          {...{
            title: "Parents",
            description: "Number of Parents",
            count: Intl.NumberFormat().format(parents?.data?.count ?? 0),
            loading: parents.isFetching,
            icon: <IconUser size={100} />,
          }}
        />
        <ReportCard
          {...{
            title: "Kids",
            description: "Number of Kids",

            count: Intl.NumberFormat().format(kids?.data?.count ?? 0),
            loading: kids.isFetching,
            icon: <StudentIcon className="w-8 h-8" />,
          }}
        />
      </div>

      <div className="w-full p-2 mt-4 border rounded shadow">
        <div className="flex justify-between px-2 mb-2">
          <Text className="text-sm text-gray-600 text-semibold">
            Kids status by transportation time
          </Text>
        </div>
        <div className="flex justify-center py-4 space-x-4">
          <div className="flex-col space-y-4">
            {kidsStatusByTransportationTime?.data?.map((status: any) => (
              <ReportCard
                {...{
                  title: status?.TransportationTime,
                  description: "Number of Kids",
                  count: Intl.NumberFormat().format(status.count ?? 0),
                  loading: kidsStatusByTransportationTime.isFetching,
                  icon: <StudentIcon className="w-8 h-8" />,
                }}
              />
            ))}
          </div>
          <div className="flex justify-center p-2 border rounded shadow h-80 shadow-sky-100">
            {genderGroupedKids?.isLoading ? (
              <LoadingOverlay visible />
            ) : (
              donut_state_data && (
                <Doughnut options={donut_options} data={donut_state_data} />
              )
            )}
          </div>
          <div className="flex space-x-4">
            <div className="w-full p-2 mt-4 border rounded">
              <div className="flex justify-between px-2 mb-2">
                <Text className="text-sm text-gray-600 text-semibold">
                  Parents status
                </Text>
                <Text
                  onClick={() => setOpenExport(true)}
                  className="flex items-center text-sm cursor-pointer "
                >
                  <IconFileExport className="text-gray-300" />
                  <span className="text-gray-400">Export</span>
                </Text>
                <Text className="text-sm text-primary text-semibold">
                  {"Total: "}

                  {Intl.NumberFormat().format(parents?.data?.count ?? 0)}
                </Text>
              </div>
              {groupedParents?.data && (
                <div className="flex items-center justify-center h-full">
                  <StatsRing
                    ringSize={90}
                    cols={2}
                    data={parentsCircleProgress}
                  />
                </div>
              )}
            </div>
            <div className="w-full p-2 mt-4 border rounded">
              <div className="flex justify-between px-2 mb-2">
                <Text className="text-sm text-gray-600 text-semibold">
                  Kids status
                </Text>
                <Text
                  onClick={() => setOpenKidsExport(true)}
                  className="flex items-center text-sm cursor-pointer"
                >
                  <IconFileExport className="text-gray-300" />
                  <span className="text-gray-400">Export</span>
                </Text>
                <Text className="text-sm text-primary text-semibold">
                  {"Total: "}

                  {Intl.NumberFormat().format(kids?.data?.count ?? 0)}
                </Text>
              </div>
              <div className="flex items-center justify-center h-full">
                {kids?.data && (
                  <StatsRing cols={2} ringSize={90} data={kidsCircleProgress} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-col justify-between p-2 mt-5 space-y-4 border rounded shadow ">
          {" "}
          <div className="flex space-x-12 ">
            {" "}
            <Radio.Group
              // label="Date Format"
              defaultValue={createdDateFormat}
              onChange={(e) => {
                setCreatedDateFormat(e);
              }}
            >
              <Group mt="xs">
                <Radio value="month" label="Monthly" />
                <Radio value="year" label="Yearly" />
              </Group>
            </Radio.Group>
          </div>
          <div className="flex justify-between space-x-2 shadow">
            <div className="flex justify-center w-full p-2 border rounded shadow-lg h-92 shadow-sky-100">
              {createdDateResponse?.isLoading ? (
                <LoadingOverlay visible />
              ) : (
                bar_state_data && (
                  <Bar options={bar_options} data={bar_state_data} />
                )
              )}
            </div>
          </div>
        </div>
      </div>
      {openExport && (
        <ParentsExportModal
          open={openExport}
          onClose={() => setOpenExport(false)}
        />
      )}
      {kidsExport && (
        <KidsExportModal
          open={kidsExport}
          onClose={() => setOpenKidsExport(false)}
        />
      )}
    </div>
  );
}
