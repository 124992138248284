import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  MultiSelect,
  Select,
  Tabs,
} from "@mantine/core";
import { DatePickerInput, TimeInput } from "@mantine/dates";
import {
  IconArchive,
  IconArrowBackUp,
  IconDeviceFloppy,
  IconPlus,
  IconTrash,
} from "@tabler/icons";
import { IconUserDollar } from "@tabler/icons-react";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { CollectionQuery } from "../../../models/collection.model";
import { Kid } from "../../../models/kids.model";
import { Parent } from "../../../models/parent.model";
import { Provider } from "../../../models/provider.model";
import { RequestForm } from "../../../models/request.model";
import { School } from "../../../models/school.model";
import ActivityLogWrapperComponent from "../../../shared/component/ActivityLog/activityLog-wrapper-component";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import { Constants } from "../../../shared/constants/Constants";
import {
  useLazyGetParentKidsQuery,
  useLazyGetParentsQuery,
} from "../../parent/store/parent.query";
import { useLazyGetProvidersQuery } from "../../provider/store/provider.query";
import { useLazyGetSchoolsQuery } from "../../school/store/school.query";
import {
  useArchiveRequestMutation,
  useAssignDriverForRequestMutation,
  useCreateRequestMutation,
  useDeleteRequestMutation,
  useLazyGetArchivedRequestQuery,
  useLazyGetRequestQuery,
  usePayForRequestManuallyMutation,
  useRestoreRequestMutation,
  useUpdateRequestMutation,
} from "../store/request.query";
export interface Props {
  editMode: string;
}

export default function NewRequestComponent(props: Props) {
  const { editMode } = props;
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  let schema = Yup.object().shape({
    kids: Yup.array()
      .of(Yup.string())
      .min(1)
      .required("Select at least one kid"),
    parentId: Yup.string()
      .typeError("Parent should be a string.")
      .required("Parent is required"),
    schoolId: Yup.string()
      .typeError("School should be a string.")
      .required("School is required"),
    // .required("Parent is required"),
    requestDate: Yup.string().typeError("Request Date should be a string"),
    // .required("Request Date should be a string"),
    requestTime: Yup.string().typeError("Request Time should be a string"),
    // .required("Request Time is required"),
    pickupFrom: Yup.string()
      .typeError("Pickup From should be a string")
      .required("Pickup From is required"),
  });

  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });

  const defaultValue: RequestForm = {
    parentId: "",
    kids: [],
    pickupFrom: "",
    schoolId: "",
    requestDate: new Date(),
    requestTime: "",
  };

  const [getRequest, request] = useLazyGetRequestQuery();
  const [getArchivedRequest, archivedRequest] =
    useLazyGetArchivedRequestQuery();
  const [createRequest, createRequestResponse] = useCreateRequestMutation();
  const [updateRequest, updateRequestResponse] = useUpdateRequestMutation();
  const [deleteRequest, deleteRequestResponse] = useDeleteRequestMutation();
  const [restoreRequest, restoreRequestResponse] = useRestoreRequestMutation();
  const [archiveRequest, archiveRequestResponse] = useArchiveRequestMutation();
  const [assignDriver, assignDriverResponse] =
    useAssignDriverForRequestMutation();
  const [payManually, payManuallyResponse] = usePayForRequestManuallyMutation();

  const [getKids, kids] = useLazyGetParentKidsQuery();
  const [getParents, parents] = useLazyGetParentsQuery();
  const [getSchools, schools] = useLazyGetSchoolsQuery();
  const [getDrivers, drivers] = useLazyGetProvidersQuery();

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<RequestForm>({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: defaultValue,
  });

  const [time, setTime] = useState("00:00");
  const [parentSearch, setParentSearch] = useState<string | undefined>("");

  const [driverSearch, setDriverSearch] = useState<string | undefined>("");

  const [openDriverModal, setOpenDriverModal] = useState<boolean>(false);
  const [selectedDriver, setSelectedDriver] = useState<string | null>(null);
  const [parentId, setParentId] = useState<string | undefined>("");
  const [schoolId, setSchoolId] = useState<string | undefined>("");
  const [parentCollection, setParentCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });
  const [kidCollection, setKidCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });
  const [schoolCollection, setSchoolCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });
  const [driverCollection, setDriverCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
  });

  useEffect(() => {
    getParents(parentCollection);
  }, [parentCollection]);
  useEffect(() => {
    getSchools(schoolCollection);
  }, [schoolCollection]);
  useEffect(() => {
    getDrivers(driverCollection);
  }, [driverCollection]);

  useEffect(() => {
    if (parentId) {
      getKids({ id: parentId, collection: kidCollection });
    }
  }, [parentId, kidCollection]);

  function setupRequest(response: any) {
    let kidoption: string[] = [];

    response.data?.requestKids?.map((kid: any) => {
      kidoption.push(kid.kidId);
    });

    setTime(
      `${response.data?.requestTime?.split(":")[0]}:${
        response.data?.requestTime?.split(":")[1]
      }`
    );
    setParentId(response.data.parentId);
    setSchoolId(response.data.schoolId);

    setValue("kids", kidoption);
    setValue("parentId", response.data.parentId);
    // setValue("parentId", response.data.parentId);
    setValue("pickupFrom", response.data.pickupFrom);
    setValue("requestDate", new Date(response.data.requestDate));
    setValue("schoolId", response?.data?.parentId);
    setValue(
      "requestTime",
      `${response.data.requestTime.split(":")[0]}:${
        response.data.requestTime.split(":")[1]
      }`
    );
  }

  function handlePayment() {
    payManually({
      amount: request?.data?.paymentAmount,
      parentId: request?.data?.parentId,
      requestId: request?.data?.id,
    })
      .unwrap()
      .then((response) => {});
  }

  useEffect(() => {
    if (editMode === "detail") {
      reset();
      if (params?.type === "archived") {
        getArchivedRequest(`${params?.id}`).then((response: any) => {
          setupRequest(response);
        });
      } else {
        getRequest(`${params?.id}`).then((response: any) => {
          setupRequest(response);
        });
      }
    } else {
      reset();
    }
  }, [params.id, editMode, getRequest, getArchivedRequest]);
  function onSubmit(data: RequestForm) {
    data.requestTime = time;
    data.parentId = parentId;
    //@ts-ignore
    data.requestDate = dayjs(data.requestDate)?.format("YYYY-MM-DD");
    if (editMode === "new") {
      createRequest(data)
        .unwrap()
        .then(() => {
          reset(defaultValue);
        })
        .catch((error: any) => {});
    } else {
      const request: any = { id: params.id, ...data };
      updateRequest(request)
        .unwrap()
        .then(() => {
          //
        })
        .catch((error) => {});
    }
  }

  function parseParents() {
    const data: { value: string; label: string }[] = [];

    if (parents.data) {
      parents?.data?.data.map((parent: Parent) => {
        let label = `${parent.name} (${parent.phoneNumber})`;

        data.push({
          value: parent.id ?? "",
          label: label,
        });
      });
    }

    if (data.length === 0) {
      return [{ value: "empty", label: "List empty", disabled: true }];
    }
    return data;
  }
  function parseSchools() {
    const data: { value: string; label: string }[] = [];

    if (schools.data) {
      schools?.data?.data.map((school: School) => {
        let label = `${school.name} (${school.phoneNumber})`;

        data.push({
          value: school.id ?? "",
          label: label,
        });
      });
    }

    if (data.length === 0) {
      return [{ value: "empty", label: "List empty", disabled: true }];
    }
    return data;
  }
  function _parseDrivers() {
    const data: { value: string; label: string }[] = [];

    if (drivers.data) {
      drivers?.data?.data.map((driver: Provider) => {
        let label = `${driver.name} (${driver.phoneNumber})`;

        data.push({
          value: driver.id ?? "",
          label: label,
        });
      });
    }

    if (data.length === 0) {
      return [{ value: "empty", label: "List empty", disabled: true }];
    }
    return data;
  }
  function parseKids() {
    const data: { value: string; label: string }[] = [];
    if (kids.data) {
      let kidOption = kids.data.data.filter(
        (kid: Kid) => kid.schoolId === schoolId
      );
      kidOption.map((kid: Kid) => {
        let label = `${kid.name}`;

        data.push({
          value: kid.id ?? "",
          label: label,
        });
      });
    }
    if (data.length === 0) {
      return [{ value: "empty", label: "List empty", disabled: true }];
    }
    return data;
  }

  const onError = (errors: any, e: any) => {};

  const [activeTab, setActiveTab] = useState<string | null>("main");

  return (
    <div className="relative flex justify-center w-full h-full space-x-2 dark:bg-gray-700 dark:text-white">
      <ActivityLogWrapperComponent
        title={
          editMode === "new"
            ? "New Request"
            : request?.data?.parent?.name
            ? request?.data?.parent?.name + "'s Request"
            : "Anonymous Request"
        }
        item={request}
        path={`/request/detail/${params?.type}/${params.id}`}
        id={params.id ?? ""}
      >
        <LoadingOverlay
          visible={
            editMode === "detail" &&
            (request.isFetching ||
              createRequestResponse.isLoading ||
              updateRequestResponse.isLoading)
          }
        />
        <Tabs defaultValue="main" value={activeTab} onTabChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="main">Main</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="main" pt="xs">
            <div className="flex justify-center h-full p-2">
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="w-full gap-y-4 dark:text-white"
              >
                <div className="flex-col space-y-4">
                  <div className="flex justify-between w-full space-x-3">
                    <Select
                      radius={"xs"}
                      classNames={{ label: "dark:text-white" }}
                      className="w-full"
                      searchable
                      clearable
                      onKeyUp={debounce((event: any) => {
                        event.target.value
                          ? setParentCollection({
                              ...parentCollection,

                              search: event.target.value,
                              searchFrom: ["name", "phoneNumber"],
                            })
                          : setParentCollection({
                              ...parentCollection,

                              search: "",
                              searchFrom: [],
                            });
                      }, 1000)}
                      onChange={(value) => {
                        setParentId(value ?? "");
                        setValue("parentId", value ?? "");
                        setSchoolId("");
                        setValue("schoolId", "");
                        setValue("kids", []);
                      }}
                      value={parentId}
                      error={errors?.parentId && `${errors?.parentId?.message}`}
                      label="Parent"
                      placeholder="Parent"
                      data={parseParents()}
                      withAsterisk
                    />
                    <Select
                      radius={"xs"}
                      classNames={{ label: "dark:text-white" }}
                      className="w-full"
                      searchable
                      onKeyUp={debounce((event: any) => {
                        event.target.value
                          ? setSchoolCollection({
                              ...schoolCollection,

                              search: event.target.value,
                              searchFrom: ["name", "phoneNumber"],
                            })
                          : setSchoolCollection({
                              ...schoolCollection,

                              search: "",
                              searchFrom: [],
                            });
                      }, 1000)}
                      onChange={(value) => {
                        setSchoolId(value ?? "");
                        setValue("schoolId", value ?? "");
                        setValue("kids", []);
                      }}
                      value={schoolId}
                      error={errors?.schoolId && `${errors?.schoolId?.message}`}
                      label="School"
                      placeholder="School"
                      data={parseSchools()}
                      withAsterisk
                    />

                    <Controller
                      render={({ field }) => (
                        <MultiSelect
                          radius={"xs"}
                          classNames={{ label: "dark:text-white" }}
                          className="w-full"
                          error={errors?.kids && `${errors?.kids?.message}`}
                          label="Kids"
                          nothingFound="Nothing Found..."
                          placeholder="Kids"
                          data={parseKids()}
                          withAsterisk
                          {...field}
                        />
                      )}
                      name="kids"
                      control={control}
                    />
                  </div>
                  <div className="flex justify-between w-full space-x-3">
                    <Controller
                      control={control}
                      name="requestDate"
                      render={({ field }) => (
                        <DatePickerInput
                          className="w-full"
                          error={
                            errors.requestDate &&
                            `${errors.requestDate?.message}`
                          }
                          //@ts-ignore
                          placeholder="Requested For"
                          label="Requested For"
                          excludeDate={(sDate) => {
                            const date = new Date();
                            if (sDate.getFullYear() < date.getFullYear()) {
                              return true;
                            } else if (
                              sDate.getFullYear() === date.getFullYear() &&
                              sDate.getMonth() < date.getMonth()
                            ) {
                              return true;
                            } else {
                              if (
                                sDate.getFullYear() <= date.getFullYear() &&
                                sDate.getMonth() === date.getMonth() &&
                                sDate.getDate() <= date.getDate()
                              ) {
                                return true;
                              } else {
                                return false;
                              }
                            }
                          }}
                          withAsterisk
                          {...field}
                        />
                      )}
                    />

                    <TimeInput
                      label="Time"
                      withAsterisk
                      value={time}
                      onChange={(time) => {
                        setTime(time?.target?.value);
                      }}
                    />

                    <Controller
                      render={({ field }) => (
                        <Select
                          radius={"xs"}
                          classNames={{ label: "dark:text-white" }}
                          className="w-full"
                          error={
                            errors?.pickupFrom &&
                            `${errors?.pickupFrom?.message}`
                          }
                          label="Pickup From"
                          placeholder="Pickup From"
                          data={["Home", "School"]}
                          withAsterisk
                          {...field}
                        />
                      )}
                      name="pickupFrom"
                      control={control}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-end w-full">
                  <Group position="right" mt="xl">
                    {editMode === "new" && (
                      <>
                        <Button
                          classNames={{ label: "flex space-x-1 " }}
                          variant="default"
                          className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                          type="reset"
                          size="xs"
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 fill-current"
                              viewBox="0 0 32 32"
                            >
                              <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                            </svg>
                          </span>
                          <span>Reset</span>
                        </Button>

                        <Button
                          variant="filled"
                          className="text-white bg-primary"
                          type="submit"
                          classNames={{ label: "flex space-x-1" }}
                          size={"xs"}
                          loading={createRequestResponse.isLoading}
                        >
                          <span>
                            <IconDeviceFloppy size={22} strokeWidth={1.5} />
                          </span>
                          <span>Submit</span>
                        </Button>
                      </>
                    )}

                    {editMode === "detail" && (
                      <>
                        {editMode === "detail" && (
                          <>
                            {(params?.type === "archived" &&
                              archivedRequest?.data?.deletedAt) ||
                            (params?.type === "active" &&
                              request?.data?.deletedAt) ? (
                              <>
                                <Confirmation
                                  type={"danger"}
                                  message={
                                    "Are you sure you want to delete it permanently?"
                                  }
                                  onYes={() => {
                                    deleteRequest(`${params?.id}`).then(
                                      (response: any) => {
                                        if (response?.data) {
                                          navigate(-1);
                                        }
                                      }
                                    );
                                  }}
                                  header={`Permanent Delete confirmation `}
                                >
                                  <Button
                                    variant="filled"
                                    loading={deleteRequestResponse.isLoading}
                                    className={`bg-danger text-white flex space-x-2 items-center`}
                                    type="button"
                                    classNames={{ label: "flex space-x-1" }}
                                    size={"xs"}
                                  >
                                    <IconTrash size={20} strokeWidth={1.5} />
                                    <span>Delete</span>{" "}
                                  </Button>
                                </Confirmation>
                                <Confirmation
                                  type={"notify"}
                                  message={`Are you sure you want to restore it`}
                                  onYes={() => restoreRequest(`${params?.id}`)}
                                  header={`Restore confirmation `}
                                >
                                  <Button
                                    variant="filled"
                                    loading={restoreRequestResponse.isLoading}
                                    className={`bg-green-500 text-white flex space-x-2 items-center`}
                                    type="button"
                                    classNames={{ label: "flex space-x-1" }}
                                    size={"xs"}
                                  >
                                    <IconArrowBackUp
                                      size={20}
                                      strokeWidth={1.5}
                                    />
                                    <span>Restore</span>
                                  </Button>
                                </Confirmation>
                              </>
                            ) : (
                              <>
                                {(request?.data?.isPaid === false ||
                                  archivedRequest?.data?.isPaid === false) && (
                                  <>
                                    <Confirmation
                                      type={"notify"}
                                      message={
                                        "Are you sure you want to perform manual payment?"
                                      }
                                      onYes={() => handlePayment()}
                                      header={`Manual Payment`}
                                    >
                                      <Button
                                        variant="filled"
                                        className="text-white bg-primary"
                                        classNames={{ label: "flex space-x-1" }}
                                        type="button"
                                        size={"xs"}
                                        loading={payManuallyResponse.isLoading}
                                      >
                                        <IconUserDollar
                                          size={20}
                                          strokeWidth={1.5}
                                        />{" "}
                                        Pay
                                      </Button>
                                    </Confirmation>
                                  </>
                                )}

                                {(request?.data?.isPaid === true ||
                                  archivedRequest?.data?.isPaid === true) && (
                                  <Button
                                    classNames={{ label: "flex space-x-1 " }}
                                    variant="default"
                                    className="relative flex items-center px-2 py-1 text-white rounded cursor-pointer bg-primary"
                                    type="button"
                                    onClick={() => {
                                      setDriverSearch(
                                        request?.data?.driver?.name ?? ""
                                      );
                                      setSelectedDriver(
                                        request?.data?.driver?.id ?? ""
                                      );
                                      setOpenDriverModal(true);
                                    }}
                                    size="xs"
                                  >
                                    <span>
                                      <IconPlus size={20} />
                                    </span>
                                    <span>
                                      {request.data?.driverId ||
                                      archivedRequest?.data?.driverId
                                        ? "Reassign Driver"
                                        : "Assign Driver"}
                                    </span>
                                  </Button>
                                )}
                                <Confirmation
                                  type={"notify"}
                                  message={
                                    "Are you sure you want to archive this request?"
                                  }
                                  header={"Archive Confirmation"}
                                  result={"single"}
                                  resultRequired={true}
                                  selectorLabel={"Please select a reason"}
                                  labelDescription={
                                    "Why are you archiving this request?"
                                  }
                                  options={Constants.ArchiveReason.map(
                                    (reason) => {
                                      return { label: reason, value: reason };
                                    }
                                  )}
                                  customInput={true}
                                  yesText={"Archive"}
                                  onYes={(data: string) => {
                                    archiveRequest({
                                      id: `${params.id}`,
                                      reason: data,
                                    });
                                  }}
                                >
                                  <Button
                                    variant="filled"
                                    loading={archiveRequestResponse.isLoading}
                                    className="text-white bg-danger"
                                    type="button"
                                    classNames={{ label: "flex space-x-1" }}
                                    size={"xs"}
                                  >
                                    <span>
                                      <IconArchive
                                        size={22}
                                        strokeWidth={1.5}
                                      />
                                    </span>
                                    <span>Archive</span>
                                  </Button>
                                </Confirmation>

                                <Button
                                  variant="filled"
                                  className="text-white bg-primary"
                                  type="submit"
                                  classNames={{ label: "flex space-x-1" }}
                                  size={"xs"}
                                  loading={updateRequestResponse.isLoading}
                                  // disabled={
                                  //   request?.data?.driverId ? true : false
                                  // }
                                >
                                  <span>
                                    <IconDeviceFloppy
                                      size={22}
                                      strokeWidth={1.5}
                                    />
                                  </span>
                                  <span>Update</span>
                                </Button>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Group>
                </div>
              </form>
            </div>
          </Tabs.Panel>
        </Tabs>
      </ActivityLogWrapperComponent>

      <Modal
        size={"lg"}
        opened={openDriverModal}
        onClose={() => setOpenDriverModal(false)}
        title="Assign Driver"
      >
        <div className="w-full h-56">
          <Select
            searchable
            radius={"xs"}
            className="pt-4"
            dropdownPosition="bottom"
            value={selectedDriver}
            classNames={{ label: "dark:text-white" }}
            onKeyUp={debounce((e) => {
              setDriverCollection({
                ...driverCollection,
                search: e.target.value,
                searchFrom: ["name", "phoneNumber"],
              });
            }, 1000)}
            label="Driver"
            placeholder="Driver"
            data={_parseDrivers()}
            onChange={(value) => setSelectedDriver(value ?? "")}
            withAsterisk
          />
        </div>
        <Group position="right" mt="xl">
          <>
            <Button
              classNames={{ label: "flex space-x-1 " }}
              variant="default"
              className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
              type="reset"
              onClick={() => setSelectedDriver(null)}
              size="xs"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 fill-current"
                  viewBox="0 0 32 32"
                >
                  <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                </svg>
              </span>
              <span>Reset</span>
            </Button>
            <Button
              variant="filled"
              className="text-white bg-primary"
              type="submit"
              classNames={{ label: "flex space-x-1" }}
              size={"xs"}
              loading={assignDriverResponse.isLoading}
              onClick={() => {
                assignDriver({
                  driverId: selectedDriver ?? "",
                  requestId: params?.id ?? "",
                });
                setOpenDriverModal(false);
              }}
            >
              <span>
                <IconDeviceFloppy size={22} strokeWidth={1.5} />
              </span>
              <span>Submit</span>
            </Button>
          </>
        </Group>
      </Modal>
    </div>
  );
}
