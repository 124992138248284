/* eslint-disable @typescript-eslint/no-explicit-any */
import { Booking } from "../../../models/booking.model";
import { Collection, CollectionQuery } from "../../../models/collection.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { ChapaPaymentStatus } from "../../../shared/utility/enum/other.enum";
import { appApi } from "../../../store/app.api";
import { BOOKING_ENDPOINT } from "../booking.endpoint";

let currentCollection: CollectionQuery;
let archivedCollection: CollectionQuery;

const bookingQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getBookingLists: builder.query<Collection<Booking>, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${BOOKING_ENDPOINT.list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          currentCollection = param;
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),
    getBooking: builder.query<Booking, string>({
      query: (id: string) => ({
        url: `${BOOKING_ENDPOINT.booking}/${id}`,
        method: "GET",
      }),
    }),
    cancelBooking: builder.mutation<Booking, any>({
      query: (data: { id: string; reason: string }) => ({
        url: `${BOOKING_ENDPOINT.cancel_booking}`,
        method: "POST",
        data: data,
        permissions: "manage-booking",
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            bookingQuery.util.updateQueryData(
              "getBookingLists",
              currentCollection,

              (draft) => {
                draft.data = draft?.data?.map((booking: Booking) => {
                  if (booking?.id === param.id) {
                    return { ...booking, status: data.status };
                  }
                  return booking;
                });
              }
            )
          );

          dispatch(
            bookingQuery.util.updateQueryData(
              "getBooking",
              data?.id,
              (draft) => {
                draft.status = data.status;
              }
            )
          );
          notification("success", "Successfully cancelled");
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    updatePaymentStatus: builder.mutation<
      Booking,
      { bookingId: string; paymentStatus: ChapaPaymentStatus }
    >({
      query: (data) => ({
        url: `${BOOKING_ENDPOINT.approve_booking_payment}`,
        method: "PUT",
        data: data,
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            bookingQuery.util.updateQueryData(
              "getBookingLists",
              currentCollection,

              (draft) => {
                draft.data = draft?.data?.map((booking: Booking) => {
                  if (booking?.id === param.bookingId) {
                    return data;
                  }
                  return booking;
                });
              }
            )
          );

          dispatch(
            bookingQuery.util.updateQueryData(
              "getBooking",
              data?.id,
              (draft) => {
                draft.paymentStatus = data.paymentStatus;
              }
            )
          );

          notification(
            "success",
            `Status Successfully changed to ${param.paymentStatus}`
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    updateInitialPaymentStatus: builder.mutation<
      Booking,
      { bookingId: string; initialPaymentStatus: ChapaPaymentStatus }
    >({
      query: (data) => ({
        url: `${BOOKING_ENDPOINT.approve_booking_initial_payment}`,
        method: "PUT",
        data: data,
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            bookingQuery.util.updateQueryData(
              "getBookingLists",
              currentCollection,

              (draft) => {
                draft.data = draft?.data?.map((booking: Booking) => {
                  if (booking?.id === param.bookingId) {
                    return data;
                  }
                  return booking;
                });
              }
            )
          );

          dispatch(
            bookingQuery.util.updateQueryData(
              "getBooking",
              data?.id,
              (draft) => {
                draft.initialPaymentStatus = data.initialPaymentStatus;
              }
            )
          );
          notification(
            "success",
            `Status Successfully changed to ${param.initialPaymentStatus}`
          );
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useLazyGetBookingQuery,
  useLazyGetBookingListsQuery,
  useCancelBookingMutation,
  useUpdatePaymentStatusMutation,
  useUpdateInitialPaymentStatusMutation,
} = bookingQuery;
