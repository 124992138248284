import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Divider,
  Group,
  LoadingOverlay,
  Modal,
  MultiSelect,
  Select,
  Stack,
  Tabs,
  Text,
  TextInput,
} from "@mantine/core";
import {
  IconArchive,
  IconArrowBackUp,
  IconCurrentLocation,
  IconDeviceFloppy,
  IconPlus,
  IconTrash,
} from "@tabler/icons";

import { debounce } from "lodash-es";
import { Suspense, lazy, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Category, CategoryStatus } from "../../../models/category.model";
import { CollectionQuery, Filter } from "../../../models/collection.model";
import { DriverAssignment } from "../../../models/driver-assignment.model";
import GroupAssignment, {
  GroupAssignmentRequest,
} from "../../../models/group-assignment.model";
import {
  GroupStatus,
  Group as KidGroup,
  NumberOfSeat,
} from "../../../models/group.model";
import { Kid } from "../../../models/kids.model";
import { Parent, ParentStatus } from "../../../models/parent.model";
import { Provider } from "../../../models/provider.model";
import ActivityLogWrapperComponent from "../../../shared/component/ActivityLog/activityLog-wrapper-component";
import { Location } from "../../../shared/component/Map/map-component";
import Confirmation from "../../../shared/component/confirmation/action-confirmation";
import EmptyIcon from "../../../shared/component/empty-icon/empty-icon";
import { notification } from "../../../shared/component/notification/utility/notification";
import { Constants } from "../../../shared/constants/Constants";
import { useLazyGetCategoriesQuery } from "../../category/store/category.query";
import { useLazyGetAllProvidersDashboardQuery } from "../../dashboard/store/dashboard.query";
import { useLazyGetKidsQuery } from "../../parent/store/parent.query";
import { useLazyGetSchoolsQuery } from "../../school/store/school.query";
import { dateFormat } from "../../../shared/utility/date-format/date-format";
import {
  useArchiveGroupMutation,
  useCreateDriverAssignmentForGroupMutation,
  useCreateGroupAssignmentMutation,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useLazyGetArchivedGroupQuery,
  useLazyGetAssignmentsByGroupQuery,
  useLazyGetGroupQuery,
  useLazyGetParentsForAssignmentQuery,
  useRemoveGroupAssignmentMutation,
  useRestoreGroupMutation,
  useToggleStatusMutation,
  useUpdateDriverAssignmentMutation,
  useUpdateGroupMutation,
} from "../store/group.query";

const MapMarkerComponent = lazy(
  () => import("../../../shared/component/Map/map-marker-component")
);

export interface Props {
  editMode: string;
}

export default function NewGroupComponent(props: Props) {
  const { editMode } = props;
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const defaultValue: KidGroup = {
    id: "",
    name: "",
    numberOfSeat: NumberOfSeat.TaxiGroup,
    availableSeats: 0,
    kidsRoadDifficultyCost: 0,
  };

  let schema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Name should have at least 3 letters")
      .max(100, "Name should have at most 100 letters")
      .required("Name is required"),
    kidsRoadDifficultyCost: Yup.number()
      .typeError("Road difficulty cost should be a number")
      .nullable(true),
    categoryId: Yup.string().required("Category is required"),
  });

  const [collection, setCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    filter: [
      [
        {
          field: "enabled",
          operator: "=",
          value: true,
        },
      ],
    ],
  });
  const [categoryCollection, setCategoryCollection] = useState<CollectionQuery>(
    {
      skip: 0,
      top: 100,
      filter: [
        [
          {
            field: "isForKabbaKids",
            operator: "=",
            value: CategoryStatus.Both,
          },
          {
            field: "isForKabbaKids",
            operator: "=",
            value: CategoryStatus.ForKabbaKids,
          },
        ],
      ],
    }
  );
  const [parentCollection, setParentCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: ["kids", "kids.school"],
  });
  const [assignmentCollection, setAssignmentCollection] =
    useState<CollectionQuery>({
      skip: 0,
      top: 10,
      includes: ["group", "kid", "school", "driver", "parent"],
    });

  const [kidsCollection, setKidsCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 100,
  });

  const [driverCollection, setDriverCollection] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    searchFrom: ["name", "phoneNumber"],
    filter: [
      [{ field: "vehicleId", value: "", operator: "notNull" }],
      [{ field: "vehicle.deletedAt", value: "", operator: "isNull" }],
      [{ field: "enabled", value: true, operator: "=" }],
      [{ field: "vehicle.enabled", value: true, operator: "=" }],
    ],
  });

  const [getGroup, group] = useLazyGetGroupQuery();
  const [getArchivedGroup, archivedGroup] = useLazyGetArchivedGroupQuery();
  const [createGroup, createResponse] = useCreateGroupMutation();
  const [updateGroup, updateResponse] = useUpdateGroupMutation();
  const [archiveGroup, archiveResponse] = useArchiveGroupMutation();
  const [getDrivers, drivers] = useLazyGetAllProvidersDashboardQuery();

  const [getParents, parents] = useLazyGetParentsForAssignmentQuery();
  const [getAssignmentByGroup, assignmentByGroup] =
    useLazyGetAssignmentsByGroupQuery();
  const [getSchools, schools] = useLazyGetSchoolsQuery();

  const [restoreGroup, restoreCall] = useRestoreGroupMutation();

  const [getCategories, categories] = useLazyGetCategoriesQuery();

  const [toggleStatus, toggleResponse] = useToggleStatusMutation();

  const [getKids, kids] = useLazyGetKidsQuery();
  const [addGroupAssignment, addResponse] = useCreateGroupAssignmentMutation();
  const [removeGroupAssignment, removeAssignmentResponse] =
    useRemoveGroupAssignmentMutation();

  const [deleteGroup, deleteCall] = useDeleteGroupMutation();

  const [reassignDriver, reassignDriverResponse] =
    useUpdateDriverAssignmentMutation();

  const locationDefault = { lat: 8.993441, lng: 38.7663447 };

  const [schoolLocation, setSchoolLocation] =
    useState<Location>(locationDefault);
  const [selectedSchool, setSelectedSchool] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [assignedParents, setAssignedParents] = useState<Parent[]>([]);

  const [locationLoading, setLocationLoading] = useState<boolean>(false);
  const [selectedParents, setSelectedParents] = useState<Parent[]>([]);
  const [showAddAssignment, setShowAddAssignment] = useState<boolean>(false);

  const [createAssignment, createAssignmentResponse] =
    useCreateDriverAssignmentForGroupMutation();

  const [selectedKids, setSelectedKids] = useState<string[]>([]);
  const [openDriverModal, setOpenDriverModal] = useState<boolean>(false);
  const [selectedDriver, setSelectedDriver] = useState<string | null>(null);

  useEffect(() => {
    getDrivers(driverCollection);
  }, [driverCollection]);

  function checkLocation() {
    setLocationLoading(true);
    setTimeout(async () => {
      await navigator.geolocation.getCurrentPosition(
        (position) => {
          setSchoolLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          notification("success", "Location has been resolved successfully!");
          setLocationLoading(false);
        },
        (err) => notification("error", "Error resolving your location!")
      );
    }, 0);
  }

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<KidGroup>({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: defaultValue,
  });

  const [, setGroupId] = useState("");

  useEffect(() => {
    if (editMode === "detail") {
      getSchools(collection);
    }
  }, [collection]);

  useEffect(() => {
    if (editMode === "detail" && params.id) {
      getAssignmentByGroup({ id: params.id, q: assignmentCollection });
    }
  }, [assignmentCollection, params?.id]);

  useEffect(() => {
    getCategories(categoryCollection);
  }, [categoryCollection]);

  useEffect(() => {
    if (editMode === "detail") {
      reset();
      if (params?.type === "archived") {
        getArchivedGroup(`${params?.id}`).then((response: any) => {
          reset(response.data);
          setGroupId(response.data.id);
          setValue("numberOfSeat", response.data.numberOfSeat);
        });
      } else {
        getGroup(`${params?.id}`).then((response: any) => {
          reset(response.data);
          setGroupId(response.data.id);
          setValue("numberOfSeat", response.data.numberOfSeat);
        });
      }
    } else {
      reset();
    }
  }, [params.id, editMode, getGroup]);

  useEffect(() => {
    if (editMode === "detail") {
      let p: Filter[][] = [];

      p.push([
        {
          field: "status",
          value: ParentStatus.Agreed,
          operator: "=",
        },
        {
          field: "status",
          value: ParentStatus.WaitingToBeAssigned,
          operator: "=",
        },
        {
          field: "status",
          value: ParentStatus.PartiallyAssigned,
          operator: "=",
        },
      ]);
      if (selectedCategory) {
        p.push([
          {
            field: "kids.categoryId",
            value: selectedCategory,
            operator: "=",
          },
        ]);
      }

      if (selectedSchool) {
        p.push([
          {
            field: "kids.schoolId",
            value: selectedSchool,
            operator: "=",
          },
        ]);

        getParents({
          ...parentCollection,
          filter: p,
        }).then((response: any) => {
          setAssignedParents(response.data.data);
        });
      }
    }
  }, [
    collection,
    schoolLocation,
    setAssignedParents,
    selectedSchool,
    selectedCategory,
  ]);

  useEffect(() => {
    if (selectedParents && selectedParents.length > 0) {
      const parentIds = selectedParents.map((parent: Parent) => parent.id);

      const filter = selectedParents?.map((parent) => {
        return {
          field: "parentId",
          operator: "=",
          value: parent.id,
        };
      });
      getKids({
        ...kidsCollection,
        includes: ["school", "parent", "category"],
        filter: [
          filter,
          [{ field: "schoolId", operator: "=", value: selectedSchool }],
          [{ field: "assigned", operator: "=", value: false }],
        ],
      });
    }
  }, [selectedParents, getKids]);

  function onSubmit(data: any) {
    categories?.data?.data?.forEach((category: Category) => {
      if (category?.id === data.categoryId) {
        data.numberOfSeat = category.capacity;
        data.availableSeats = category.capacity;
      }
    });
    if (editMode === "new") {
      createGroup(data)
        .unwrap()
        .then(() => reset(defaultValue))
        .catch((error) => {});
    } else {
      const group: KidGroup = { id: params.id, ...data };
      updateGroup(group)
        .unwrap()
        .then(() => {})
        .catch((error) => {});
    }
  }

  const onError = (errors: any, e: any) => {};

  function setName(name: string) {}

  function setSearchLocation(position: google.maps.LatLngLiteral) {
    setSchoolLocation({ lat: position.lat, lng: position.lng });
  }

  function _parseCategories() {
    const data: { value: string; label: string }[] = [];

    if (categories?.data?.data) {
      categories.data.data.forEach((category: Category) => {
        if (category.id && category.name) {
          data.push({ value: category.id, label: category.name });
        }
      });
    }

    return data;
  }

  function addAssignmentCall() {
    const assignments = selectedKids?.map((kid) => ({
      kidId: kid,
      schoolId: selectedSchool,
    }));

    const request: GroupAssignmentRequest = {
      assignments,
      groupId: params?.id,
    };

    addGroupAssignment(request);
  }

  function assignDriverCall() {
    if (selectedDriver) {
      if (group?.data?.driverId) {
        const driverAssignments = group?.data?.driverAssignments?.filter(
          (assignment: DriverAssignment) =>
            assignment?.driverId == group?.data?.driverId
        );
        const driverAssignmentId: any = driverAssignments
          ? driverAssignments?.length > 0
            ? driverAssignments[0]["id"]
            : ""
          : "";
        reassignDriver({
          id: driverAssignmentId ?? "",
          driverId: selectedDriver ?? "",
          groupId: params.id ?? "",
        })
          .unwrap()
          .then((response) => {
            setOpenDriverModal(false);
          });
      } else {
        createAssignment({
          driverId: selectedDriver,
          groupId: params?.id,
        })
          .unwrap()
          .then((response) => {
            setOpenDriverModal(false);
          })
          .catch((error) => {});
      }
    }
  }

  function _parseDrivers() {
    const data: { label: string; value: string }[] = [];

    if (drivers?.data?.data) {
      drivers?.data?.data?.forEach((driver: Provider) => {
        let label = `${driver.name} ( ${driver.phoneNumber})`;
        if (driver.vehicle && driver.vehicle.enabled) {
          label =
            label +
            " (" +
            driver?.vehicle?.model +
            ", " +
            driver?.vehicle?.plateNumber +
            ")";
        }
        data.push({ label: label, value: driver.id ?? "" });
      });
    }
    return data;
  }

  const kidOption = kids?.data?.data
    ? kids?.data?.data?.map((kid: Kid) => ({
        value: kid?.id,
        label: `(${kid?.category?.name}) ${kid.name} `,
        group: kid?.parent?.name + `(${kid?.parent?.phoneNumber})`,
      }))
    : [{ value: "", label: "there are no kids available" }];

  const [activeTab, setActiveTab] = useState<string | null>("main");

  return (
    <div className="relative flex justify-center w-full h-full space-x-2 dark:bg-gray-700 dark:text-white">
      <ActivityLogWrapperComponent
        title={editMode === "new" ? "New Group" : group?.data?.name ?? ""}
        item={group}
        path={`/registration/group/detail/${params?.type}/${params.id}`}
        id={params.id ?? ""}
      >
        <LoadingOverlay
          visible={
            editMode === "detail" &&
            (group.isFetching || updateResponse.isLoading)
          }
        />
        <Tabs defaultValue="main" value={activeTab} onTabChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="main">Main</Tabs.Tab>
            {editMode === "detail" && <Tabs.Tab value="map">Map</Tabs.Tab>}
            {showAddAssignment && (
              <Tabs.Tab value="add-assignment">Add Assignment</Tabs.Tab>
            )}
            {editMode === "detail" && (
              <Tabs.Tab value="assignments">Assignments</Tabs.Tab>
            )}
          </Tabs.List>

          <Tabs.Panel value="main" pt="xs">
            <div className="flex justify-center h-full p-2">
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="w-full gap-y-4 dark:text-white"
              >
                <div className="flex-col space-y-4">
                  <div className="flex justify-between w-full space-x-3">
                    <TextInput
                      classNames={{ label: "dark:text-white" }}
                      className="w-full"
                      error={errors.name && `${errors?.name?.message}`}
                      withAsterisk
                      label="Name"
                      placeholder="Name"
                      {...register("name")}
                    />

                    <Controller
                      render={({ field }) => (
                        <Select
                          radius={"xs"}
                          classNames={{ label: "dark:text-white" }}
                          className="w-full"
                          error={
                            errors?.categoryId &&
                            `${errors?.categoryId?.message}`
                          }
                          label="Category"
                          placeholder="Category"
                          data={_parseCategories()}
                          withAsterisk
                          {...field}
                        />
                      )}
                      name="categoryId"
                      control={control}
                    />

                    <TextInput
                      classNames={{ label: "dark:text-white" }}
                      className="w-full"
                      error={
                        errors.kidsRoadDifficultyCost &&
                        `${errors?.kidsRoadDifficultyCost?.message}`
                      }
                      // withAsterisk
                      label="Road difficulty cost"
                      placeholder="Road difficulty cost"
                      {...register("kidsRoadDifficultyCost")}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-end w-full">
                  <Group position="right" mt="xl">
                    {editMode === "new" && (
                      <>
                        <Button
                          classNames={{ label: "flex space-x-1 " }}
                          variant="default"
                          className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                          type="reset"
                          size="xs"
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 fill-current"
                              viewBox="0 0 32 32"
                            >
                              <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                            </svg>
                          </span>
                          <span>Reset</span>
                        </Button>
                        <Button
                          variant="filled"
                          className="text-white bg-primary"
                          type="submit"
                          classNames={{ label: "flex space-x-1" }}
                          size={"xs"}
                          loading={createResponse.isLoading}
                        >
                          <span>
                            <IconDeviceFloppy size={22} strokeWidth={1.5} />
                          </span>
                          <span>Submit</span>
                        </Button>
                      </>
                    )}
                    {editMode === "detail" && (
                      <>
                        {editMode === "detail" && (
                          <>
                            {(params?.type === "archived" &&
                              archivedGroup?.data?.deletedAt) ||
                            (params?.type === "active" &&
                              group?.data?.deletedAt) ? (
                              <>
                                <Confirmation
                                  type={"danger"}
                                  message={
                                    "Are you sure you want to delete it permanently?"
                                  }
                                  onYes={() =>
                                    deleteGroup(`${params?.id}`).then(
                                      (response: any) => {
                                        if (response?.data) {
                                          navigate(-1);
                                        }
                                      }
                                    )
                                  }
                                  header={`Permanent Delete confirmation `}
                                >
                                  <Button
                                    variant="filled"
                                    loading={deleteCall.isLoading}
                                    className={`bg-danger text-white flex space-x-2 items-center`}
                                    type="button"
                                    classNames={{ label: "flex space-x-1" }}
                                    size={"xs"}
                                  >
                                    <IconTrash size={20} strokeWidth={1.5} />
                                    <span>Delete</span>{" "}
                                  </Button>
                                </Confirmation>
                                <Confirmation
                                  type={"notify"}
                                  message={`Are you sure you want to restore it`}
                                  onYes={() => restoreGroup(`${params?.id}`)}
                                  header={`Restore confirmation `}
                                >
                                  <Button
                                    variant="filled"
                                    loading={restoreCall.isLoading}
                                    className={`bg-green-500 text-white flex space-x-2 items-center`}
                                    type="button"
                                    classNames={{ label: "flex space-x-1" }}
                                    size={"xs"}
                                  >
                                    <IconArrowBackUp
                                      size={20}
                                      strokeWidth={1.5}
                                    />
                                    <span>Restore</span>
                                  </Button>
                                </Confirmation>
                              </>
                            ) : (
                              <>
                                <Confirmation
                                  type={"notify"}
                                  message={
                                    "Are you sure you want to archive this group?"
                                  }
                                  header={"Archive Confirmation"}
                                  result={"single"}
                                  resultRequired={true}
                                  selectorLabel={"Please select a reason"}
                                  labelDescription={
                                    "Why are you archiving this group?"
                                  }
                                  options={Constants.ArchiveReason.map(
                                    (reason) => {
                                      return { label: reason, value: reason };
                                    }
                                  )}
                                  customInput={true}
                                  yesText={"Archive"}
                                  onYes={(data: string) => {
                                    archiveGroup({
                                      id: `${params.id}`,
                                      reason: data,
                                    });
                                  }}
                                >
                                  <Button
                                    variant="filled"
                                    loading={archiveResponse.isLoading}
                                    className="text-white bg-danger"
                                    type="button"
                                    classNames={{ label: "flex space-x-1" }}
                                    size={"xs"}
                                  >
                                    <span>
                                      <IconArchive
                                        size={22}
                                        strokeWidth={1.5}
                                      />
                                    </span>
                                    <span>Archive</span>
                                  </Button>
                                </Confirmation>
                                {params?.type === "active" && (
                                  <Confirmation
                                    type={"notify"}
                                    message={
                                      "Are you sure you want to " +
                                      (group.data?.status == GroupStatus.Active
                                        ? "deactivate"
                                        : "activate") +
                                      " it?"
                                    }
                                    onYes={() =>
                                      toggleStatus(`${params.id}`).then(
                                        () => {}
                                      )
                                    }
                                    header={
                                      (group.data?.status == GroupStatus.Active
                                        ? "Deactivate"
                                        : "Activate") + " Confirmation"
                                    }
                                  >
                                    <Button
                                      variant="filled"
                                      loading={toggleResponse.isLoading}
                                      className={`${
                                        group.data?.status == GroupStatus.Active
                                          ? " bg-danger"
                                          : "bg-green-500"
                                      } text-white`}
                                      type="button"
                                      classNames={{ label: "flex space-x-1" }}
                                      size={"xs"}
                                    >
                                      <span>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-6 fill-current"
                                          viewBox="0 0 48 48"
                                        >
                                          <path d="M23.5 5C17.16639 5 12 10.16639 12 16.5C12 18.130861 12.341389 19.689962 12.957031 21.099609 A 1.50015 1.50015 0 1 0 15.707031 19.900391C15.252673 18.860038 15 17.713139 15 16.5C15 11.78761 18.78761 8 23.5 8C28.21239 8 32 11.78761 32 16.5C32 17.08427 31.94138 17.652314 31.830078 18.201172 A 1.50015 1.50015 0 1 0 34.769531 18.798828C34.92023 18.055686 35 17.28573 35 16.5C35 10.16639 29.83361 5 23.5 5 z M 23.5 12C21.032499 12 19 14.032499 19 16.5L19 25.759766L18.138672 25.404297C14.483804 23.900444 10.334734 26.436466 10.005859 30.375 A 1.50015 1.50015 0 0 0 10.693359 31.765625L19.957031 37.667969C20.601036 38.078718 21.151526 38.620029 21.576172 39.255859L23.033203 41.441406 A 1.50015 1.50015 0 0 0 23.035156 41.441406C23.803886 42.591886 25.189849 43.186167 26.554688 42.945312L31.882812 42.005859C33.603893 41.703285 34.998876 40.422039 35.449219 38.734375 A 1.50015 1.50015 0 0 0 35.451172 38.726562L37.832031 29.576172C38.653863 26.49462 36.64066 23.318721 33.501953 22.748047L28 21.748047L28 16.5C28 14.032499 25.967501 12 23.5 12 z M 23.5 15C24.346499 15 25 15.653501 25 16.5L25 23 A 1.50015 1.50015 0 0 0 26.232422 24.476562L32.964844 25.699219C34.424137 25.964545 35.315668 27.370273 34.933594 28.802734 A 1.50015 1.50015 0 0 0 34.929688 28.8125L32.550781 37.960938C32.399124 38.529274 31.940201 38.949356 31.363281 39.050781 A 1.50015 1.50015 0 0 0 31.363281 39.052734L26.033203 39.992188C25.834042 40.027338 25.642567 39.944908 25.529297 39.775391L24.072266 37.591797C23.417016 36.610136 22.565912 35.77367 21.570312 35.138672 A 1.50015 1.50015 0 0 0 21.568359 35.138672L13.251953 29.835938C13.814585 28.352335 15.413607 27.528548 16.996094 28.179688L19.929688 29.386719 A 1.50015 1.50015 0 0 0 22 28L22 16.5C22 15.653501 22.653501 15 23.5 15 z" />
                                        </svg>
                                      </span>
                                      {group.data?.status ==
                                      GroupStatus.Active ? (
                                        <span>Deactivate</span>
                                      ) : (
                                        <span>Activate</span>
                                      )}
                                    </Button>
                                  </Confirmation>
                                )}
                                <Button
                                  variant="filled"
                                  className="text-white bg-primary"
                                  type="submit"
                                  classNames={{ label: "flex space-x-1" }}
                                  size={"xs"}
                                  loading={updateResponse.isLoading}
                                >
                                  <span>
                                    <IconDeviceFloppy
                                      size={22}
                                      strokeWidth={1.5}
                                    />
                                  </span>
                                  <span>Update</span>
                                </Button>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Group>
                </div>
              </form>
            </div>
          </Tabs.Panel>

          {editMode === "detail" && (
            <>
              <Tabs.Panel value="map" pt="xs">
                <div className="flex justify-between h-full p-5">
                  <div>
                    <Text>Latitude: {schoolLocation.lat}</Text>
                    <Text>Longitude: {schoolLocation.lng}</Text>
                  </div>
                  <div className="flex-col space-y-4">
                    <Button
                      classNames={{ label: "flex space-x-1 " }}
                      variant="default"
                      className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                      leftIcon={<IconCurrentLocation />}
                      loading={locationLoading}
                      onClick={checkLocation}
                    >
                      <span>Load Current Location</span>
                    </Button>
                  </div>
                </div>
                <Divider className={"my-2"} />

                {/*StaticMap Component*/}
                <Suspense>
                  <MapMarkerComponent
                    setLocation={setSchoolLocation}
                    location={schoolLocation}
                    placesProps={{
                      setLocation: setSearchLocation,
                      setName: setName,
                    }}
                    schools={schools?.data?.data ?? []}
                    parents={assignedParents ?? []}
                    setSelectedSchool={setSelectedSchool}
                    selectedParents={selectedParents}
                    setSelectedParents={setSelectedParents}
                    categories={categories?.data?.data ?? []}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    setCollection={setCollection}
                    setCategoryCollection={setCategoryCollection}
                    setParentCollection={setParentCollection}
                  />
                </Suspense>

                <Divider className={"my-2"} />
                <div className="flex items-center justify-end w-full">
                  <Button
                    variant="filled"
                    className="text-white bg-primary"
                    classNames={{ label: "flex space-x-1" }}
                    size={"xs"}
                    loading={locationLoading}
                    disabled={selectedParents.length < 1}
                    onClick={() => {
                      setShowAddAssignment(true);
                      setActiveTab("add-assignment");
                    }}
                  >
                    <span>
                      <IconDeviceFloppy size={22} strokeWidth={1.5} />
                    </span>
                    <span>
                      Submit (
                      {selectedParents.length > 0
                        ? selectedParents.length
                        : "0"}{" "}
                      parents selected)
                    </span>
                  </Button>
                </div>
              </Tabs.Panel>
              <Tabs.Panel value="assignments">
                <div className={"min-w-max overflow-x-auto"}>
                  <Stack align={"flex-end"} className={"mt-3 mb-5"}>
                    <Group position="right">
                      <Button
                        onClick={() => {
                          setOpenDriverModal(true);
                          setSelectedDriver(null);
                          if (group?.data?.driverId) {
                            setSelectedDriver(group?.data?.driverId);
                          }
                        }}
                        className="relative flex items-center px-2 py-1 text-white rounded cursor-pointer bg-primary"
                      >
                        <IconPlus size={20} />
                        <div className={"pl-2"}>
                          {group?.data?.driverId
                            ? "Reassign Driver"
                            : "Assign Driver"}
                        </div>
                      </Button>
                    </Group>
                  </Stack>
                </div>

                <Modal
                  size={"lg"}
                  opened={openDriverModal}
                  onClose={() => setOpenDriverModal(false)}
                  title="Assign Driver"
                >
                  <div className="w-full h-56">
                    <Select
                      searchable
                      radius={"xs"}
                      className="pt-4"
                      dropdownPosition="bottom"
                      value={selectedDriver}
                      classNames={{ label: "dark:text-white" }}
                      onKeyUp={debounce((e) => {
                        setDriverCollection({
                          ...driverCollection,
                          search: e.target.value,
                        });
                      }, 1000)}
                      label="Driver"
                      placeholder="Driver"
                      data={_parseDrivers()}
                      onChange={(value) => setSelectedDriver(value ?? "")}
                      withAsterisk
                    />
                  </div>
                  <Group position="right" mt="xl">
                    <>
                      <Button
                        classNames={{ label: "flex space-x-1 " }}
                        variant="default"
                        className="dark:text-white hover:bg-primary hover:dark:bg_dark_primary hover:text-white"
                        type="reset"
                        onClick={() => setSelectedDriver(null)}
                        size="xs"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 fill-current"
                            viewBox="0 0 32 32"
                          >
                            <path d="M18.90625 4.09375C18.101563 4.09375 17.265625 4.367188 16.625 4.9375L16.625 4.96875L16.59375 5L4.90625 16.59375C3.695313 17.804688 3.703125 19.777344 4.84375 21.0625L4.875 21.09375L4.90625 21.09375L10.90625 27.09375C11.511719 27.699219 12.320313 27.992188 13.125 28L28 28L28 26L16.5 26L27 15.5C28.265625 14.234375 28.304688 12.210938 27.09375 11L21.09375 5C20.488281 4.394531 19.710938 4.09375 18.90625 4.09375 Z M 18.875 6.125C19.195313 6.125 19.492188 6.210938 19.6875 6.40625L25.6875 12.40625C26.074219 12.792969 26.128906 13.558594 25.59375 14.09375L20.5625 19.125L12.90625 11.46875L17.96875 6.4375L18 6.40625C18.253906 6.195313 18.570313 6.125 18.875 6.125 Z M 11.46875 12.90625L19.125 20.5625L14.03125 25.65625C14.019531 25.664063 14.011719 25.679688 14 25.6875C13.484375 26.117188 12.691406 26.066406 12.3125 25.6875L6.34375 19.75C6.328125 19.730469 6.328125 19.707031 6.3125 19.6875C5.902344 19.171875 5.9375 18.375 6.3125 18Z" />
                          </svg>
                        </span>
                        <span>Reset</span>
                      </Button>
                      <Button
                        variant="filled"
                        className="text-white bg-primary"
                        type="submit"
                        classNames={{ label: "flex space-x-1" }}
                        size={"xs"}
                        loading={
                          createAssignmentResponse.isLoading ||
                          reassignDriverResponse.isLoading ||
                          assignmentByGroup.isLoading
                        }
                        onClick={() => {
                          assignDriverCall();
                        }}
                      >
                        <span>
                          <IconDeviceFloppy size={22} strokeWidth={1.5} />
                        </span>
                        <span>
                          {group?.data?.driverId ? "Reassign" : "Submit"}
                        </span>
                      </Button>
                    </>
                  </Group>
                </Modal>

                <div className="relative overflow-x-auto">
                  <LoadingOverlay visible={assignmentByGroup.isFetching} />
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Group
                        </th>
                        <th scope="col" className="px-6 py-3">
                          School
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Transportation Time
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Travel With
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Parent
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Kid
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Driver
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Amount
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Registered At
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Status
                        </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {assignmentByGroup.isSuccess &&
                      assignmentByGroup.data.data.length > 0 ? (
                        assignmentByGroup.data?.data.map(
                          (assignment: GroupAssignment, idx: number) => (
                            <tr
                              key={idx}
                              className="text-xs bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                            >
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                              >
                                {assignment?.group?.name}
                              </th>
                              <td className="px-6 py-4 text-xs">
                                {assignment?.school?.name}
                              </td>
                              <td className="px-6 py-4 text-xs">
                                {assignment?.kid?.transportationTime}
                              </td>
                              <td className="px-6 py-4 text-xs">
                                {assignment?.kid?.kidTravelStatus}
                              </td>
                              <td className="px-6 py-4 text-xs">
                                {assignment?.parent?.name} (
                                {assignment?.parent?.phoneNumber})
                              </td>
                              <td className="px-6 py-4 text-xs">
                                {assignment?.kid?.name}
                              </td>
                              <td className="px-6 py-4">
                                {assignment?.driver?.name &&
                                assignment?.driver?.phoneNumber ? (
                                  <>
                                    {assignment?.driver?.name} (
                                    {assignment?.driver?.phoneNumber})
                                  </>
                                ) : (
                                  "Unassigned"
                                )}
                              </td>
                              <td className="px-6 py-4 text-center">
                                {assignment?.amount?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "ETB",
                                })}
                              </td>
                              <td className="px-6 py-4 text-center">
                                {dateFormat(assignment?.createdAt)}
                              </td>

                              <td className="px-6 py-4 text-center">
                                {assignment?.status ? "Active" : "Inactive"}
                              </td>
                              <td className="px-2 py-4">
                                <div className="flex space-x-2">
                                  <Confirmation
                                    type={"notify"}
                                    message={
                                      "Are you sure you want to remove this group assignment?"
                                    }
                                    header={"Delete Confirmation"}
                                    yesText={"Remove"}
                                    onYes={(data: string) => {
                                      removeGroupAssignment({
                                        id: assignment?.id ?? "",
                                        groupId: assignment?.groupId ?? "",
                                      });
                                    }}
                                  >
                                    <Button
                                      variant="filled"
                                      loading={
                                        removeAssignmentResponse.isLoading
                                      }
                                      className="text-white bg-danger"
                                      type="button"
                                      classNames={{ label: "flex space-x-1" }}
                                      size={"xs"}
                                    >
                                      <span>
                                        <IconArchive
                                          size={22}
                                          strokeWidth={1.5}
                                        />
                                      </span>
                                      {/*<span>Archive</span>*/}
                                      {/*<Tooltip label={"Archive this kid"}>*/}
                                      {/*    Archive this kid*/}
                                      {/*</Tooltip>*/}
                                    </Button>
                                  </Confirmation>
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>

                  {(assignmentByGroup?.data?.data?.length ?? 0) < 1 ? (
                    <div className="relative flex items-center justify-center w-full h-56">
                      {/* <LoadingOverlay
                              visible={kids.isLoading}
                              overlayBlur={2}
                          /> */}
                      <EmptyIcon />
                    </div>
                  ) : (
                    <></>
                  )}

                  {/* {kids?.isSuccess &&
                kids?.data?.data?.length > 0 && (
                    <div className="flex items-center justify-end w-full mt-2 mb-2">
                        <Pagination
                            total={
                                kids?.data ? kids?.data?.count : 0
                            }
                            defaultPageSize={10}
                            pageSize={[5, 10, 15, 20]}
                            onPaginationChange={(skip: number, top: number) => {
                                setCollection({...collection, skip: skip, top: top});
                            }}
                        />
                    </div>
                )} */}
                </div>
              </Tabs.Panel>
            </>
          )}

          {showAddAssignment && (
            <Tabs.Panel value="add-assignment">
              <div className="flex justify-center h-full mt-3">
                <Stack
                  classNames={{ label: "dark:text-white" }}
                  className="w-full"
                >
                  <MultiSelect
                    data={kidOption}
                    label="Select Kids"
                    placeholder="Select Kids"
                    onChange={(values: string[]) => setSelectedKids(values)}
                    radius={"xs"}
                    classNames={{ label: "dark:text-white" }}
                    className="w-full"
                  />

                  <Button
                    variant="filled"
                    className="text-white bg-primary"
                    classNames={{ label: "flex space-x-1" }}
                    size={"xs"}
                    loading={addResponse.isLoading}
                    onClick={() => {
                      addAssignmentCall();
                    }}
                  >
                    <span>
                      <IconDeviceFloppy size={22} strokeWidth={1.5} />
                    </span>
                    <span>Submit</span>
                  </Button>
                </Stack>
              </div>
            </Tabs.Panel>
          )}
        </Tabs>
      </ActivityLogWrapperComponent>
    </div>
  );
}
