import { Collection, CollectionQuery } from "../../../models/collection.model";
import { DriverFee } from "../../../models/driver-fee.model";
import { Provider } from "../../../models/provider.model";
import { RouteAssignment } from "../../../models/route-assignment.model";
import { notification } from "../../../shared/component/notification/utility/notification";
import { collectionQueryBuilder } from "../../../shared/utility/collection-builder/collection-query-builder";
import { appApi } from "../../../store/app.api";
import { ROUTE_ENDPOINT } from "../route-assignment.endpoint";

let routeCollection: CollectionQuery;
let routeAssignmentCollection: CollectionQuery;
let assignmentWithBookingsCollection: CollectionQuery;
let archivedRouteCollection: CollectionQuery;
let driverFeesCollection: CollectionQuery;
let routeBusStationsCollection: { query: CollectionQuery; id: string };
let routePricesCollection: { query: CollectionQuery; routeId: string };

const routeAssignmentQuery = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getRouteAssignment: builder.query<RouteAssignment, string>({
      query: (id: string) => ({
        url: `${ROUTE_ENDPOINT.get_route_assignment}/${id}`,
        method: "GET",
      }),
    }),
    getRouteAssignments: builder.query<
      Collection<RouteAssignment>,
      CollectionQuery
    >({
      query: (data) => ({
        url: `${ROUTE_ENDPOINT.get_route_assignments}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            routeAssignmentCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),
    getAssignmentsWithBookings: builder.query<
      Collection<RouteAssignment>,
      CollectionQuery
    >({
      query: (data) => ({
        url: `${ROUTE_ENDPOINT.get_route_assignments_with_bookings}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            assignmentWithBookingsCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
    }),
    addRouteAssignment: builder.mutation<RouteAssignment, any>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.add_route_assignment}`,
        method: "post",
        data: newData,
        permissions: "assignments/manage-assignments",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully added");
            dispatch(
              routeAssignmentQuery.util.updateQueryData(
                "getRouteAssignments",
                routeAssignmentCollection,
                (draft) => {
                  if (data) {
                    draft.data.unshift(data);
                    draft.count += 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    changeAssigmentAvailablity: builder.mutation<RouteAssignment, string>({
      query: (id: string) => ({
        url: `${ROUTE_ENDPOINT.toggleAssigmentStatus}/${id}`,
        method: "POST",
        permissions: "manage-assignments",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              routeAssignmentQuery.util.updateQueryData(
                "getRouteAssignments",
                routeAssignmentCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((assign) => {
                      if (assign?.id === param) {
                        return {
                          ...assign,
                          isVisible: data?.isVisible,
                        };
                      }
                      return assign;
                    });
                  }
                }
              )
            );
            notification("success", "Successfully changed");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    updateRouteAssignment: builder.mutation<RouteAssignment, any>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.update_route_assignment}`,
        method: "put",
        data: newData,
        permissions: "assignments/manage-assignments",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
            dispatch(
              routeAssignmentQuery.util.updateQueryData(
                "getRouteAssignments",
                routeAssignmentCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((assignment) => {
                      if (assignment.id === data.id) {
                        return data;
                      } else {
                        return assignment;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    completeRouteAssignment: builder.mutation<RouteAssignment, any>({
      query: (id: any) => ({
        url: `${ROUTE_ENDPOINT.completeAssignment}/${id}`,
        method: "post",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully completed");
            dispatch(
              routeAssignmentQuery.util.updateQueryData(
                "getRouteAssignments",
                routeAssignmentCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((assignment) => {
                      if (assignment.id === data.id) {
                        return data;
                      } else {
                        return assignment;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    cancelRouteAssignment: builder.mutation<
      RouteAssignment,
      { id: string; cancelledReason: { reason: string } }
    >({
      query: (data) => ({
        url: `${ROUTE_ENDPOINT.cancel_route_assignment}`,
        method: "post",
        data: data,
        permissions: "assignments/manage-assignments",
      }),

      async onQueryStarted(query, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              routeAssignmentQuery.util.updateQueryData(
                "getRouteAssignments",
                routeAssignmentCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft?.data?.map((assignment) => {
                      if (assignment.id === data.id) {
                        return data;
                      } else {
                        return assignment;
                      }
                    });
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    deleteRouteAssignment: builder.mutation<boolean, string>({
      query: (id: string) => ({
        url: `${ROUTE_ENDPOINT.delete_route_assignment}/${id}`,
        method: "delete",
        permissions: "assignments/manage-assignments",
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully deleted");
            dispatch(
              routeAssignmentQuery.util.updateQueryData(
                "getRouteAssignments",
                routeAssignmentCollection,
                (draft) => {
                  if (data) {
                    draft.data = draft.data.filter(
                      (assignment) => assignment.id !== id
                    );
                    draft.count -= 1;
                  }
                }
              )
            );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getDriverFees: builder.query<Collection<DriverFee>, CollectionQuery>({
      query: (data) => ({
        url: `${ROUTE_ENDPOINT.driver_fee_list}`,
        method: "GET",
        params: collectionQueryBuilder(data),
      }),
      async onQueryStarted(param, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            driverFeesCollection = param;
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "error try again"
          );
        }
      },
      providesTags: ["payment_status", "driver_fee"],
    }),

    getDriverFee: builder.query<DriverFee, string>({
      query: (id: string) => ({
        url: `${ROUTE_ENDPOINT.driver_fee}/${id}`,
        method: "get",
      }),
      providesTags: ["payment_status"],
    }),

    addAssignmentPayment: builder.mutation<
      DriverFee[],
      {
        year: number;
        month: string;
        driverIds: string[] | undefined;
        routeIds: string[] | undefined;
      }
    >({
      query: (data: any) => ({
        url: `${ROUTE_ENDPOINT.pay_for_assignments}`,
        method: "post",
        data: data,
      }),
      invalidatesTags: ["driver_fee"],
      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log("data", data);
          if (data) {
            notification("success", "Successfully submitted");
            // dispatch(
            //   routeAssignmentQuery.util.updateQueryData(
            //     "getDriverFees",
            //     driverFeesCollection,
            //     (draft) => {
            //       if (data.length > 1) {
            //         draft.data = [...draft.data, ...data];
            //         // draft.data.unshift(data);
            //         draft.count += data.length;
            //       }
            //     }
            //   )
            // );
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),

    getDriverAssignments: builder.query<any, any>({
      query: (data: { collection: CollectionQuery; id: string }) => ({
        url: `${ROUTE_ENDPOINT.driver_assignments}/${data.id}`,
        method: "GET",
        params: collectionQueryBuilder(data.collection),
      }),
    }),
    getRouteDrivers: builder.query<
      Collection<Provider>,
      { query: CollectionQuery; routeId: string }
    >({
      query: (data) => ({
        url: `${ROUTE_ENDPOINT.route_drivers}/${data.routeId}`,
        method: "GET",
        params: collectionQueryBuilder(data.query),
      }),
    }),
    paymentForMultipleAssignment: builder.mutation<any, any>({
      query: (newData: any) => ({
        url: `${ROUTE_ENDPOINT.payment_for_multiple_assignments}`,
        method: "post",
        data: newData,
        responseType: "arraybuffer",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully created");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
    }),
    driversFeeStatusUpdate: builder.mutation<
      any,
      {
        driverFeeId: string;
        paymentStatus: string;
        paymentMethod: string;
        bankName: string;
        transactionNumber?: string;
      }
    >({
      query: (data: any) => ({
        url: `${ROUTE_ENDPOINT.driver_fee_status_update}`,
        method: "post",
        data: data,
        permissions: "assignments/manage-assignments",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
      invalidatesTags: ["payment_status"],
    }),
    driversFeesStatusUpdate: builder.mutation<
      any,
      {
        driverFeeIds: string[];
        paymentStatus: string;
        paymentMethod: string;
        bankName: string;
      }
    >({
      query: (data: any) => ({
        url: `${ROUTE_ENDPOINT.driver_fees_status_update}`,
        method: "post",
        data: data,
        permissions: "assignments/manage-assignments",
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            notification("success", "Successfully updated");
          }
        } catch (error: any) {
          notification(
            "error",
            error?.error?.data?.message
              ? error?.error?.data?.message
              : "Error try again"
          );
        }
      },
      invalidatesTags: ["payment_status"],
    }),
    exportPayOuts: builder.query<any, CollectionQuery>({
      query: (data: CollectionQuery) => ({
        url: `${ROUTE_ENDPOINT.export_payouts}?${collectionQueryBuilder(data)}`,
        method: "GET",
        responseType: "arraybuffer",
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useAddAssignmentPaymentMutation,

  useCompleteRouteAssignmentMutation,

  useAddRouteAssignmentMutation,
  useCancelRouteAssignmentMutation,

  useUpdateRouteAssignmentMutation,
  useDeleteRouteAssignmentMutation,
  useLazyGetRouteAssignmentQuery,
  useLazyGetRouteAssignmentsQuery,
  useLazyGetAssignmentsWithBookingsQuery,
  useLazyGetDriverFeesQuery,
  useLazyGetDriverFeeQuery,
  useLazyGetRouteDriversQuery,
  useLazyGetDriverAssignmentsQuery,
  useDriversFeeStatusUpdateMutation,
  usePaymentForMultipleAssignmentMutation,
  useChangeAssigmentAvailablityMutation,
  useDriversFeesStatusUpdateMutation,
  useLazyExportPayOutsQuery,
} = routeAssignmentQuery;
